import React, { useState, useEffect } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import resources from './resources.json';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ResourceHubHeroGalaxy from "../../assets/images/hubelement.svg";
import Overview from './Overview';
import GigsPage from '../GigsPage/GigsPage';
import { useParams, useNavigate } from "react-router-dom";

function App() {
  const [slidesToShow, setSlidesToShow] = useState(4); 
  const { chain } = useParams();
  const navigate = useNavigate();
  const allowedChains = ["stellar", "cosmos", "flow"];

  useEffect(() => {
    console.log(chain);
    if (!allowedChains.includes(chain)) {
      navigate("/ecosystems");
    }
  }, [chain, navigate]);

  useEffect(() => {
    const breakpoints = {
      xs: 1, 
      sm: 2, 
      md: 3, 
      lg: 4, 
    };

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 684) {
        setSlidesToShow(breakpoints.xs);
      } else if (screenWidth < 1024) {
        setSlidesToShow(breakpoints.sm);
      } else if (screenWidth < 1560) {
        setSlidesToShow(breakpoints.md);
      } else {
        setSlidesToShow(breakpoints.lg);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  const miniCourses = resources.stellar.mini_courses;

  return (
    <div className="w-[97%] my-8">
      <div className="mb-16">
        <Slider {...settings}>
          {miniCourses.map((course, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-[#191919] to-black border-2 border-[#262626] h-[400px] rounded-2xl relative flex items-center"
              style={{
                background: "linear-gradient(to right, #252525, black)",
              }}
            >
              <div className="h-[60%] w-[90%] rounded-t-xl mx-auto my-auto">
                <img src={ResourceHubHeroGalaxy} />
              </div>
              <div className="h-[10%] w-full px-2 text-left flex items-left">
                <div
                  className="time m-1 px-2 h-[70%] border-2 border-[#2F135E] font-poppins flex items-center justify-center rounded-3xl text-white xl:text-sm"
                  style={{ backgroundColor: "rgba(92, 31, 196, 1)" }}
                >
                  🕐 {course.time}
                </div>
                <div
                  className="cost m-1 px-2 h-[70%] border-2 border-[#960062] font-poppins flex items-center justify-center rounded-3xl text-white xl:text-sm"
                  style={{ backgroundColor: "rgba(245, 31, 170, 1)" }}
                >
                  🎁 {course.cost}
                </div>
              </div>
              <div className="h-[15%] px-4 text-left">
                <p className="text-xl text-white font-semibold">
                  {course.title}
                </p>
              </div>
              <Link to={`/${chain}/hub/${course.id}`} key={index}>
                <div className="h-[15%] mt-1 flex justify-center">
                  <div
                    className="text-white border-2 border-[#161616] flex items-center pl-2 rounded-lg text-lg h-[70%] w-[90%]"
                    style={{
                      background: "linear-gradient(to right, #252525, black)",
                    }}
                  >
                    Get Started
                    <span className="text-white ml-auto mr-2">➚</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default App;
