import React, { useState } from "react";
import "./ToggleGigCreate.scss";
const ToggleProfile = ({ id, toggleGigs, setToggleGigs }) => {
  const handleToggle = () => {
    setToggleGigs(toggleGigs === true ? false : true);
  };

  return (
    <div className="flex items-center justify-start w-full mb-5">
      <label htmlFor={`${id}`} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            checked={toggleGigs}
            id={`${id}`}
            name={id}
            className="sr-only"
            onChange={handleToggle}
          />
          <div className="tar_c bg-gray-800 w-60 h-16">
            <p
              className={`tar_text ${
                !toggleGigs ? "text-white" : "text-gray"
              }`}
            >
              About
            </p>
            <p
              className={`tar_text ${
                toggleGigs ? "text-white" : "text-gray"
              }`}
            >
              Gigs
            </p>
          </div>
          <div
            className={`${
              !toggleGigs
                ? "translate-x-full bg-green-500"
                : "bg-green-500"
            } tar_h`}
          ></div>
        </div>
      </label>
    </div>
  );
};

export default ToggleProfile;
