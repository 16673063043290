import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import ChainPage from "./pages/ChainPage/ChainPage";
import ResourceHubPage from "./pages/ResourceHubPage/ResourceHubPage";
import GigsPage from "./pages/GigsPage/GigsPage";
import OrganizationPage from "./pages/OrganizationPage/OrganizationPage";
import CreateGigPage from "./pages/CreateGigPage/CreateGigPage";
import Ecosystems from "./pages/EcosystemPage/Ecosystems";
import ContactUs from "./pages/ContactUs/ContactUs";
import Login from "./pages/Login/Login";
import UserProfileSignup from "./pages/UserProfileSignup/UserProfileSignup";
import UserProfile from "./pages/UserProfile/UserProfile";
import RegisterOrg from "./pages/RegisterOrg/RegisterOrg";
import Auth from "./pages/Auth/authPage";
import { useEffect } from "react";
import OBCApi from "./api/api";
import resources from './pages/ResourceHubPage/resources.json';
import Overview from "./pages/ResourceHubPage/Overview";
import Tutorial from './pages/ResourceHubPage/Tutorial';

function App() {
  const miniCourses = resources.stellar.mini_courses;

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      const api = new OBCApi(localStorage.getItem("jwt"), "");
      api
        .userGetUserProfile()
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data.data));
        })
        .catch((err) => {
          localStorage.clear();
        });
    }
  });

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/ecosystems" element={<Ecosystems />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/:chain/authenticate" element={<Login />} />
      <Route path="/:chain/profile-complete" element={<UserProfileSignup />} />
      <Route path="/profile/:id" element={<UserProfile />} />
      <Route path="/:chain/" element={<ChainPage />} />
      <Route path="/:chain/register-org" element={<RegisterOrg />} />
      <Route path="/:chain/hub" element={<ResourceHubPage />} />
      <Route path="/:chain/gigs" element={<GigsPage />} />
      <Route path="/:chain/gig/create" element={<CreateGigPage />} />
      <Route path="/:chain/org/:orgname" element={<OrganizationPage />} />
      <Route path={`/:chain/hub/:courseid`} element={<Overview />} />
      <Route path="/:chain/hub/:courseid/:chapterid/:moduleid" element={<Tutorial />} />
    </Routes>
  );
}

export default App;
