import React, { useState } from "react";
import "./GigDetailPage.scss";
import Drawer from "@mui/material/Drawer";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import GradingIcon from "@mui/icons-material/Grading";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Stepper, Step, StepLabel } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import OBCApi from "../../api/api";
import { gigApplicationEntity } from "../../api/types";
import applicationsuccess from "../../assets/images/applicationsuccess.svg";
import { useParams, Link } from "react-router-dom";
import Countdown from "./Countdown";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
export default function GigDetailPage({ isOpen, onClose, gigData }) {
  const { chain } = useParams();
  const [viewState, setViewState] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const [mdValue, setMdValue] =
    React.useState(`# Getting Started with Create React App

This project was bootstrapped with [Create React App](https://github.com/facebook/create-react-app).

## Available Scripts

In the project directory, you can run:

Launches the test runner in the interactive watch mode.\
See the section about [running tests](https://facebook.github.io/create-react-app/docs/running-tests) for more information.

### ${`eject`} 

Builds the app for production to the ${`eject`}  folder.\
It correctly bundles React in production mode and optimizes the build for the best performance.

The build is minified and the filenames include the hashes.\
Your app is ready to be deployed!

See the section about [deployment](https://facebook.github.io/create-react-app/docs/deployment) for more information.

### ${`eject`} 

**Note: this is a one-way operation. Once you ${`eject`} , you can't go back!**

If you aren't satisfied with the build tool and configuration choices, you can ${`eject`} at any time. This command will remove the single build dependency from your project.

Instead, it will copy all the configuration files and the transitive dependencies (webpack, Babel, ESLint, etc) right into your project so you have full control over them. All of the commands except ${`eject`}  will still work, but they will point to the copied scripts so you can tweak them. At this point you're on your own.

You don't have to ever use ${`eject`} . The curated feature set is suitable for small and middle deployments, and you shouldn't feel obligated to use this feature. However we understand that this tool wouldn't be useful if you couldn't customize it when you are ready for it.

This section has moved here: [https://facebook.github.io/create-react-app/docs/deployment](https://facebook.github.io/create-react-app/docs/deployment)

This section has moved here: [https://facebook.github.io/create-react-app/docs/troubleshooting#npm-run-build-fails-to-minify](https://facebook.github.io/create-react-app/docs/troubleshooting#npm-run-build-fails-to-minify)
`);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const steps = ["Submission Open", "Submission Review", "Winners Announced"];
  const [formData, setFormData] = useState({
    name: "",
    tags: [],
    description: "",
    category: "",
    questions: [
      {
        category: "Textbox",
        title: "Link to your submission",
        subtitle: "Add your GitHub Repo, Notion or Figma prototype link",
        answer: "",
      },
      {
        category: "Markdown Input",
        title: "Link to your submission",
        subtitle: "Add your GitHub Repo, Notion or Figma prototype link",
        answer: "",
      },
    ],
    bountyCurrency: "",
    bountyAmounts: [""],
    expiryDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    eligibility: "",
    details: `<!-- Below is a sample template of what could be the data -->
### About the Bounty

Write details about the bounty in detail and what is expected out of the same.

### Evaluation Criteria

Write about the evaluation metrics and if there is something applicants need to keep in mind in particular.

`,
  });
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`& .${stepConnectorClasses.label}`]: {
      color: "#ffffff",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#A193F6",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#A193F6",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      flexDirection: "column",
      height: 30,
      width: 3,
      border: 0,
      backgroundColor: "#3C3B3B",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#A193F6",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#A193F6",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <FileOpenIcon />,
      2: <GradingIcon />,
      3: <EmojiEventsIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };
  console.log(gigData, "hiiii");
  const calculateDaysAgo = (createdDate) => {
    const currentDate = new Date();
    const postedDate = new Date(createdDate);
    const timeDifference = currentDate - postedDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };
  const renderTagsWithDynamicLimit = (tags, maxCharCount) => {
    if (tags) {
      let currentCharCount = 0;
      let visibleTags = [];
      let hiddenTagsCount = 0;

      for (const tag of tags) {
        if (currentCharCount + tag.length <= maxCharCount) {
          currentCharCount += tag.length;
          visibleTags.push(tag);
        } else {
          hiddenTagsCount = tags.length - visibleTags.length;
          break;
        }
      }

      return (
        <>
          {visibleTags.map((tag, tagIndex) => (
            <span
              key={tagIndex}
              className="gigdetailpage_main_left_tagsubmissioncontainer_tagcontainer_tag"
            >
              {tag}
            </span>
          ))}
          {hiddenTagsCount > 0 && (
            <>
              <span className="gigdetailpage_main_left_tagsubmissioncontainer_tagcontainer_tag">
                +{hiddenTagsCount}
              </span>
            </>
          )}
        </>
      );
    }
  };
  const handleAnswerChange = (index, value) => {
    const updatedFormData = { ...formData };
    updatedFormData.questions[index].answer = value;
    setFormData(updatedFormData);
  };

  const targetTime = new Date(gigData?.expiry_date).getTime();

  const handleFormSubmit = () => {
    setShowLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const api = new OBCApi(localStorage.getItem("jwt"), chain);
    const entity = gigApplicationEntity;
    entity.chain = chain;
    entity.data = formData.questions;
    entity.gig_id = gigData.ID;
    entity.skills = user.skills;
    api
      .gigAppCreate(entity)
      .then((res) => {
        setShowLoading(false);
        showSuccessSnack("gig application submitted succesfully!");
        setViewState(2);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  };
  return (
    <Drawer anchor={"bottom"} open={isOpen} className="gigdetailpage">
      <div
        style={{ filter: showLoading ? "blur(20px)" : "none" }}
        className="gigdetailpage_width"
      >
        <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

        <div className="gigdetailpage_closecontainer">
          <button
            className="gigdetailpage_closecontainer_closebutton"
            onClick={() => {
              setViewState(0);
              onClose();
            }}
          >
            X
          </button>
        </div>

        <div className="gigdetailpage_main">
          <div className="gigdetailpage_main_left">
            {viewState !== 2 ? (
              <>
                <div className="gigdetailpage_main_left_top">
                  <div className="gigdetailpage_main_left_top_type">
                    {gigData?.category}
                  </div>
                  <div className="gigdetailpage_main_left_top_amount">{`🏆 ${gigData?.bounty_amount}`}</div>
                </div>
                <div className="gigdetailpage_main_left_name">
                  {gigData?.name}
                </div>
                <div className="gigdetailpage_main_left_creatordetails">
                  <div className="gigdetailpage_main_left_creatordetails_image">
                    <img src={gigData?.icon} alt="gig" />
                  </div>
                  <div className="gigdetailpage_main_left_creatordetails_by">
                    by {gigData?.organisation_username}
                  </div>
                  <div className="gigdetailpage_main_left_creatordetails_date">
                    Posted {calculateDaysAgo(gigData?.CreatedAt)} days ago
                  </div>
                </div>
                <div className="gigdetailpage_main_left_tagsubmissioncontainer">
                  <div className="gigdetailpage_main_left_tagsubmissioncontainer_tagcontainer">
                    {renderTagsWithDynamicLimit(gigData?.tags, 50)}
                  </div>
                </div>
                <div className="gigdetailpage_main_left_divider"></div>
                {viewState === 0 ? (
                  <div className="gigdetailpage_main_left_detailscontainer">
                    <MDEditor.Markdown
                      source={gigData?.details}
                      className="gigdetailpage_main_left_detailscontainer_value"
                      style={{ whiteSpace: "pre-wrap" }}
                    />
                  </div>
                ) : (
                  <div className="gigdetailpage_main_left_detailscontainer">
                    <div
                      onClick={() => {
                        setViewState(0);
                      }}
                      className="gigdetailpage_main_left_detailscontainer_subtext"
                    >
                      {`<`} Back to Gig Details
                    </div>

                    {formData.questions.map((question, index) => (
                      <div
                        className="basicscomponent_inputcontainer"
                        key={index}
                      >
                        <div className="basicscomponent_inputcontainer_questiontitle">
                          {question.title}
                        </div>
                        <div className="basicscomponent_inputcontainer_questiondescription">
                          {question.subtitle}
                        </div>
                        {question.category === "Textbox" ? (
                          <textarea
                            type="text"
                            name="description"
                            className="basicscomponent_inputcontainer_value"
                            placeholder="Enter your answer"
                            value={question.answer}
                            onChange={(e) =>
                              handleAnswerChange(index, e.target.value)
                            }
                          />
                        ) : (
                          <MDEditor
                            style={{ backgroundColor: "#181818" }}
                            className="mt-2"
                            value={question.answer}
                            name="details"
                            onChange={(value) =>
                              handleAnswerChange(index, value)
                            }
                          />
                        )}
                      </div>
                    ))}
                    <div className="gigdetailpage_main_left_detailscontainer_submitform">
                      <div
                        onClick={handleFormSubmit}
                        className="gigdetailpage_main_left_detailscontainer_submitform_btn"
                      >
                        Submit Form
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                <div className="gigdetailpage_main_left_success">
                  <div className="gigdetailpage_main_left_success_icon">
                    <img src={applicationsuccess} alt="success" />
                  </div>
                  <div className="gigdetailpage_main_left_success_title">
                    Your submission was successful!
                  </div>
                  <div className="gigdetailpage_main_left_success_subtitle">
                    Keep an eye on your mailbox for further updates 💪
                  </div>
                  <div
                    onClick={() => {
                      setViewState(0);
                      onClose();
                    }}
                    className="gigdetailpage_main_left_success_submit"
                  >
                    Go to gigs
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="gigdetailpage_main_right">
            <div className="gigdetailpage_main_right_container submission">
              <div>
                <div className="submission_title">Time Remaining</div>
                <div className="submission_value"><Countdown targetTime={targetTime} /></div>
              </div>
              {viewState === 0 && (
                <div
                  onClick={() => setViewState(1)}
                  className="submission_button"
                >
                  Submit
                </div>
              )}
            </div>
            <div className="gigdetailpage_main_right_steppercontainer">
              <Stepper
                // alternativeLabel
                orientation="vertical"
                activeStep={activeStepIndex}
                connector={<ColorlibConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      <div className="text-white text-sm">Step {index + 1}</div>
                      <div className="text-white font-semibold">{label}</div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>

            <div className="gigdetailpage_main_right_container prize">
              <div className="prize_title">Prize Information</div>
              <div className="prize_info">Bounty Amount: {gigData?.bounty_amount}</div>
            </div>
            {/* <div className="gigdetailpage_main_right_container project">
            <div className="project_title">About the Project</div>
            <div className="project_detail">
              <div className="project_detail_image">
                <img src={gigData?.icon} alt="project icon" />
              </div>
              <div className="project_detail_others">
                <div className="project_detail_others_data">
                  <Link
                    to="/org/keybase"
                    className="project_detail_others_data_name"
                  >
                    Keybase
                  </Link>
                  <div className="project_detail_others_data_status">
                    Verified
                  </div>
                </div>
                <div className="project_detail_others_tags">
                  <div className="project_detail_others_tags_tag">DeFi</div>
                  <div className="project_detail_others_tags_tag">Wallets</div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="gigdetailpage_main_right_container eligibility">
              <div className="eligibility_title">Eligibility</div>
              <div className="eligibility_text">
                User needs to be from India only
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
