import React, { useEffect, useState } from "react";
import "./ChainPage.scss";
import Header from "../../components/Header/StellarHeader/Header";
import stellarhome from "../../assets/images/stellarhome.png";
import flowhome from "../../assets/images/flowhome.png";
import cosmoshome from "../../assets/images/cosmoshome.png";
import stellarCol1Image from "../../assets/images/stellar/col1.png";
import stellarCol2Image from "../../assets/images/stellar/col2.png";
import stellarCol3Row1Image from "../../assets/images/stellar/col3_row1.png";
import stellarCol3Row2Image from "../../assets/images/stellar/col3_row2.png";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
const allowedChains = ["stellar", "flow", "cosmos"];

export default function ChainPage() {
  const { chain } = useParams();
  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    console.log(chain);
    if (!allowedChains.includes(chain)) {
      navigate("/ecosystems");
    }
  }, [chain, navigate]);
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const chainData = {
    stellar: {
      banner: stellarhome,
      col1: "https://assets-global.website-files.com/5deac75ecad2173c2ccccbc7/5e3addf973b22942a6ca193b_Leadership.jpg",
      col2: "https://assets-global.website-files.com/5deac75ecad2173c2ccccbc7/5e3addf93eccb5714fce8f4a_Overviews.jpg",
      col3Row1:
        "https://miro.medium.com/v2/resize:fit:720/format:webp/0*WVORUPQQbeok_vO6.png",
      col3Row2:
        "https://assets-global.website-files.com/5deac75ecad2173c2ccccbc7/5e3b1b91a78e3a9be58b28c5_Community-min.jpg",
      mission:
        "Stellar makes it possible to create, send, and trade digital representations of all forms of money: dollars, pesos, bitcoin, pretty much anything. It's designed so all the world's financial systems can work together on a single network.",
      boxData: [
        { title: "Active Accounts", value: "11.5M+" },
        { title: "NFTs Minted", value: "21.9M" },
        { title: "Avg Cost per Transaction", value: "$0.00025" },
      ],
    },
    cosmos: {
      banner: cosmoshome,
      col1: "https://v1.cosmos.network/img/community-grid-3.1118c8e7.jpg",
      col2: "https://v1.cosmos.network/img/community-grid-2.4cb72ba8.jpg",
      col3Row1: "https://v1.cosmos.network/img/community-grid-4.87452766.jpg",
      col3Row2: "https://v1.cosmos.network/img/community-grid-6.4bff66f8.jpg",
      mission:
        "The vision of Cosmos is to make it easy for developers to build blockchains and break the barriers between blockchains by allowing them to transact with each other. The end goal is to create an Internet of Blockchains, a network of blockchains able to communicate with each other in a decentralized way.",
      boxData: [
        { title: "Apps & Services", value: "248+" },
        { title: "Digital Assets", value: "$42B+" },
        { title: "Transactions confirmed in", value: "< 7 sec" },
      ],
    },
    flow: {
      banner: flowhome,
      col1: "https://pbs.twimg.com/media/FtA9upxaAAAHiXU?format=jpg&name=medium",
      col2: "https://pbs.twimg.com/media/FsN0lwuacAA2E26?format=jpg&name=large",
      col3Row1: "https://pbs.twimg.com/media/Fw9PZvCaQAEhctz?format=jpg&name=large",
      col3Row2: "https://pbs.twimg.com/media/Fy_bXZoXgAYcpGB?format=jpg&name=medium",
      mission:
        "Flow aims to address some of the limitations of earlier blockchain platforms, such as congestion and slow transaction times, by providing a more scalable and user-friendly environment for developers and users.",
      boxData: [
        { title: "Active Accounts", value: "20M+" },
        { title: "NFT Transaction Volume", value: "#2" },
        { title: "Transactions on Flow", value: "360M+" },
      ],
    },
  };
  const specificChain = chainData[chain.toLowerCase()];

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="chainpage"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div
        style={{ backgroundImage: `url(${specificChain?.banner})` }}
        className="chainpage_banner"
      >
        {/* <img src={stellarhome} alt="stellar home"/> */}
        <div className="text-center absolute bottom-24">
          <div className="chainpage_banner_subtext">Welcome to</div>
          <div className="chainpage_banner_text">{chain} Ecosystem</div>
        </div>
      </div>
      <div className="chainpage_main">
        <div className="chainpage_main_overviewcontainer_outer">
          <div className="chainpage_main_overviewcontainer">
            <div className="chainpage_main_overviewcontainer_title">
              Mission
            </div>
            <div className="chainpage_main_overviewcontainer_text">
              {`${specificChain?.mission}`}
            </div>
          </div>
        </div>
        <div className="chainpage_main_statscontainer">
          <div className="chainpage_main_statscontainer_box">
            <div className="chainpage_main_statscontainer_box_value">
              {specificChain?.boxData[0].value}
            </div>
            <div className="chainpage_main_statscontainer_box_value_title">
              {specificChain?.boxData[0].title}
            </div>
          </div>
          <div className="chainpage_main_statscontainer_box">
            <div className="chainpage_main_statscontainer_box_value two">
              {specificChain?.boxData[1].value}
            </div>
            <div className="chainpage_main_statscontainer_box_value_title">
              {specificChain?.boxData[1].title}
            </div>
          </div>
          <div className="chainpage_main_statscontainer_box">
            <div className="chainpage_main_statscontainer_box_value three">
              {specificChain?.boxData[2].value}
            </div>
            <div className="chainpage_main_statscontainer_box_value_title">
              {specificChain?.boxData[2].title}
            </div>
          </div>
        </div>
        <div className="chainpage_main_gallerycontainer">
          <div className="chainpage_main_gallerycontainer_col1">
            <div className="chainpage_main_gallerycontainer_col1_row1">
              Join a community of millions.
            </div>
            <div className="chainpage_main_gallerycontainer_col1_row2">
              <div
                style={{ backgroundImage: `url(${specificChain?.col1})` }}
                className="chainpage_main_gallerycontainer_col1_row2_bg"
              ></div>
              <div
                style={{ backgroundImage: `url(${specificChain?.col1})` }}
                className="chainpage_main_gallerycontainer_col1_row2_img"
              ></div>
            </div>
          </div>
          <div className="chainpage_main_gallerycontainer_col2">
            <div
              style={{ backgroundImage: `url(${specificChain?.col2})` }}
              className="chainpage_main_gallerycontainer_col2_bg"
            ></div>
            <div
              style={{ backgroundImage: `url(${specificChain?.col2})` }}
              className="chainpage_main_gallerycontainer_col2_img"
            ></div>
          </div>
          <div className="chainpage_main_gallerycontainer_col3">
            <div className="chainpage_main_gallerycontainer_col3_row1">
              <div
                style={{ backgroundImage: `url(${specificChain?.col3Row1})` }}
                className="chainpage_main_gallerycontainer_col3_row1_bg"
              ></div>
              <div
                style={{ backgroundImage: `url(${specificChain?.col3Row1})` }}
                className="chainpage_main_gallerycontainer_col3_row1_img"
              ></div>{" "}
            </div>
            <div className="chainpage_main_gallerycontainer_col3_row2">
              <div
                style={{ backgroundImage: `url(${specificChain?.col3Row2})` }}
                className="chainpage_main_gallerycontainer_col3_row2_bg"
              ></div>
              <div
                style={{ backgroundImage: `url(${specificChain?.col3Row2})` }}
                className="chainpage_main_gallerycontainer_col3_row2_img"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
