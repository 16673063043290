import React, { useState } from "react";
import "./Login.scss";
import applicationsuccess from "../../assets/images/applicationsuccess.svg";
import { Link } from "react-router-dom";
import Header from "../../components/Header/StellarHeader/Header";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import { backendUrl } from "../../api/configs";
import { useParams } from "react-router-dom";
export default function Login() {
  const { chain } = useParams();
  const [formData, setFormData] = useState({
    blockchainFoundation: "",
    organizationEmail: "",
    onboardingPurpose: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setIsSubmitted(true);
  };
  const handleGoogleLogin = () => {
    //TODO: change to subdomain
    window.location.href = `${backendUrl}/auth/google/login?redirect=https://app.onboarding.club/auth`
  };

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="login"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      <div className="login_main">
        <div className="login_main_login">Get Started With</div>
        <div className="login_main_title">Onboarding.club</div>
        <div className="login_main_subtitle">
          Sign in/Register to dive deep into the world of blockchains
        </div>
        <div className="login_main_container">
          <div
            onClick={handleGoogleLogin}
            className="login_main_container_googlebutton"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_164_1367)">
                <path
                  d="M19.8042 10.2302C19.8042 9.55044 19.7491 8.86699 19.6315 8.19824H10.1992V12.0491H15.6007C15.3765 13.291 14.6563 14.3897 13.6018 15.0878V17.5864H16.8243C18.7166 15.8448 19.8042 13.2726 19.8042 10.2302Z"
                  fill="#4285F4"
                />
                <path
                  d="M10.1999 20.0008C12.897 20.0008 15.1714 19.1152 16.8286 17.5867L13.6061 15.088C12.7096 15.698 11.5521 16.0434 10.2036 16.0434C7.59474 16.0434 5.38272 14.2833 4.58904 11.917H1.26367V14.4928C2.96127 17.8696 6.41892 20.0008 10.1999 20.0008Z"
                  fill="#34A853"
                />
                <path
                  d="M4.58467 11.9168C4.16578 10.6748 4.16578 9.32995 4.58467 8.08799V5.51221H1.26297C-0.155365 8.33785 -0.155365 11.6669 1.26297 14.4925L4.58467 11.9168Z"
                  fill="#FBBC04"
                />
                <path
                  d="M10.1999 3.95805C11.6256 3.936 13.0035 4.47247 14.036 5.45722L16.8911 2.60218C15.0833 0.904587 12.6838 -0.0287217 10.1999 0.000673888C6.41892 0.000673888 2.96126 2.13185 1.26367 5.51234L4.58537 8.08813C5.37537 5.71811 7.59106 3.95805 10.1999 3.95805Z"
                  fill="#EA4335"
                />
              </g>
              <defs>
                <clipPath id="clip0_164_1367">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="login_main_container_googlebutton_text">
              Continue with Google
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
