import axios from "axios";
import { backendUrl } from "./configs";

class OBCApi {
  jwt;
  base = `${backendUrl}/v0`;
  chain;
  constructor(jwt, chain) {
    this.jwt = jwt;
    this.chain = chain;
  }

  //User const routes

  ////GET
  userGetUser(username) {
    const route = `/user/${username}`;
    return this.prepGetRequest(route, {});
  }
  userGetUserProfile() {
    const route = `/user`;
    return this.prepGetRequest(route, {});
  }
  ////PUT
  userUpdateUser(userEntity) {
    const route = `/user/`;
    return this.prepPutRequest(route, userEntity);
  }

  //Org const routes

  ////POST
  orgCreateOrg(orgEntity) {
    const route = `/${this.chain}/organisation`;
    return this.prepPostRequest(route, orgEntity);
  }

  ////GET
  orgGetOrg(username) {
    const route = `/${this.chain}/organisation/${username}`;
    return this.prepGetRequest(route, {});
  }

  ////PUT
  orgUpdateOrg(orgEntity) {
    const route = `/${this.chain}/organisation`;
    return this.prepPutRequest(route, orgEntity);
  }

  //Gigs const routes

  ////POST
  gigCreateGig(gigEntity) {
    const route = `/${this.chain}/gigs`;
    return this.prepPostRequest(route, gigEntity);
  }
  gigDeleteGig(organisation) {
    const route = `/${this.chain}/${organisation}/gigs/delete`;
    return this.prepPostRequest(route, {});
  }

  ////GET
  gigGetOrgGigs(organisation) {
    const route = `/${this.chain}/${organisation}/gigs`;
    return this.prepGetRequest(route, {});
  }
  gigGetGig(id, organisation) {
    const route = `/${this.chain}/${organisation}/gigs/${id}`;
    return this.prepGetRequest(route, {});
  }
  gigGetChainGig() {
    const route = `/${this.chain}/gigs/`;
    return this.prepGetRequest(route, {});
  }

  //Gig Applications

  ////POST
  gigAppCreate(gigAppEntity) {
    const route = `/${this.chain}/gigs/application`;
    return this.prepPostRequest(route, gigAppEntity);
  }
  gigAppUpdateGigApp(gigAppEntity) {
    const route = `/${this.chain}/gigs/update`;
    return this.prepPostRequest(route, gigAppEntity);
  }
  gigAppDeleteGigApp(id) {
    const route = `/${this.chain}/gigs/delete/${id}`;
    return this.prepPostRequest(route, {});
  }
  gigAppSelectWinner(gigApplicationWinnerEntity) {
    const route = `/${this.chain}/gigs/application/select`;
    return this.prepPostRequest(route, gigApplicationWinnerEntity);
  }

  ////GET
  gigAppGetGigApp(id) {
    const route = `/${this.chain}/gigs/application/${id}`;
    return this.prepGetRequest(route, {});
  }
  gigAppUserGigApp() {
    const route = `/${this.chain}/gigs/applications`;
    return this.prepGetRequest(route, {});
  }

  prepPostRequest(route, body) {
    return axios.post(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }

  prepGetRequest(route, body) {
    return axios.get(`${this.base}${route}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }

  prepPutRequest(route, body) {
    return axios.put(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }
}

export default OBCApi;
