import React, { useState } from "react";
import ToggleGigCreate from "../../components/Toggles/ToggleGigCreate";
import deleteicon from "../../assets/images/delete.svg";

function RewardsPage({ formData, setFormData }) {
  const categoryOptions = ["USDC", "OSMO", "XLM", "FLOW"];
  const [togglePreview, setTogglePreview] = useState(true);

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, bountyCurrency: value });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedAmounts = [...formData.bounty_amounts];
    updatedAmounts[index] = value;
    setFormData({ ...formData, bounty_amounts: updatedAmounts });
  };

  const addReward = () => {
    setFormData((prevData) => ({
      ...prevData,
      bounty_amounts: [...prevData.bounty_amounts, ""],
    }));
  };

  const deleteReward = (index) => {
    if (formData.bounty_amounts.length === 1) {
      return;
    }
    const updatedAmounts = [...formData.bounty_amounts];
    updatedAmounts.splice(index, 1);
    setFormData({ ...formData, bounty_amounts: updatedAmounts });
  };

  return (
    <div className="basicscomponent">
      <select
        name="category"
        value={formData.bountyCurrency}
        className="basicscomponent_rewardcurrency"
        onChange={handleCategoryChange}
      >
        {categoryOptions.map((option, id) => (
          <option value={option} key={id}>
            {option}
          </option>
        ))}
      </select>
      {formData.bounty_amounts.map((amount, index) => (
        <div className="basicscomponent_rewardcontainer" key={index}>
          <div className="flex justify-between">
            <div className="basicscomponent_rewardcontainer_rewardserial">
              Prize {index + 1}
            </div>
            <img
              src={deleteicon}
              alt="delete icon"
              className="w-5 h-5 cursor-pointer"
              onClick={() => deleteReward(index)}
            />
          </div>
          <input
            className="basicscomponent_rewardcontainer_input"
            name="bountyAmount"
            placeholder="Bounty Amount"
            value={amount}
            onChange={(e) => handleInputChange(e, index)}
          />
          {index === formData.bounty_amounts.length - 1 && (
            <div
              className="basicscomponent_addquestionbutton"
              onClick={addReward}
            >
              Add Another Bounty
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default RewardsPage;
