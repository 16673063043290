import React, { useState } from "react";

function EditableMultiInput({ property, value, setValue }) {
  const [inputText, setInputText] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "" && e.key === "Backspace" && value.length > 0) {
      const updatedValue = [...value];
      updatedValue.pop();
      setValue(property, updatedValue);
    } else {
      setInputText(inputValue);
    }
  };

  const handleDelete = (index) => {
    const updatedValue = [...value];
    updatedValue.splice(index, 1);
    setValue(property, updatedValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && inputText === "" && value.length > 0) {
      const updatedValue = [...value];
      updatedValue.pop();
      setValue(property, updatedValue);
    }
    if (e.key === "Enter" && inputText.trim() !== "") {
      setValue(property, [...value, inputText.trim()]);
      setInputText("");
    }
  };

  return (
    <div className="basicscomponent_inputcontainer_value">
      <div className="basicscomponent_inputcontainer_value_tags">
        {value.map((item, index) => (
          <div
            className="basicscomponent_inputcontainer_value_tags_tag"
            key={index}
          >
            {item}
            <button onClick={() => handleDelete(index)}>X</button>
          </div>
        ))}
      </div>
      <input
        type="text"
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type and press Enter for multiple values"
      />
    </div>
  );
}

export default EditableMultiInput;
