import React from 'react'
import './HomePage.scss';
import Header from '../../components/Header/Header';
import homepageTitleImage from '../../assets/images/homepage_title.png';
import exploreButton from '../../assets/images/explore_button.png';
import circle from '../../assets/images/circle.png'
import whatis from '../../assets/images/whatis.png'
import whatis_title from '../../assets/images/whatis_title.png'
import toolkit from '../../assets/images/toolkit.png'
import offerings_title from '../../assets/images/offerings_title.png'
import offerings_left from '../../assets/images/offerings_left.png'
import top_left from '../../assets/images/top_left.png'
import top_right_bg from '../../assets/images/top_right_bg.png'
import top_right_stellar from '../../assets/images/top_right_stellar.png'
import top_right_cosmos from '../../assets/images/top_right_cosmos.png'
import top_right_flow from '../../assets/images/top_right_flow.png'
import middle_left_circle from '../../assets/images/middle_left_circle.png'
import middle_left_bg from '../../assets/images/middle_left_bg.png'
import middle_right_bg from '../../assets/images/middle_right_bg.png'
import middle_right_card from '../../assets/images/middle_right_card.png'
import bottom_left_bg from '../../assets/images/bottom_left_bg.png'
import bottom_left_trophy from '../../assets/images/bottom_left_trophy.png'
import bottom_right_bg from '../../assets/images/bottom_right_bg.png'
import bottom_right_coins from '../../assets/images/bottom_right_coins.png'
import top2_right from '../../assets/images/top2_right.png'
import top2_left_button from '../../assets/images/top2_left_button.png'
import middle2_left from '../../assets/images/middle2_left.png'
import middle2_right_button from '../../assets/images/middle2_right_button.png'
import bottom2_left_button from '../../assets/images/bottom2_left_button.png'
import bottom2_right from '../../assets/images/bottom2_right.png'
import flow from '../../assets/images/flow.png'
import footer_title from '../../assets/images/footer_title.png'
import footer_button from '../../assets/images/footer_button.png'
import footer_twitter_button from '../../assets/images/footer_twitter_button.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import Loader from "../../components/Loader/Loader";
import { Link as Navlink } from "react-router-dom";

export default function HomePage() {

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(true)
    const images = [
      
      homepageTitleImage,
      exploreButton,
      circle,
      whatis,
      whatis_title,
      toolkit,
      offerings_title,
      offerings_left,
      top_left,
      top_right_bg,
      top_right_stellar,
      top_right_cosmos,
      top_right_flow,
      middle_left_circle,
      middle_left_bg,
      middle_right_bg,
      middle_right_card,
      bottom_left_bg,
      bottom_left_trophy,
      bottom_right_bg,
      bottom_right_coins,
      flow,
      footer_title,
      footer_button,
      footer_twitter_button
      
    ];

    let loadedImages = 0;

    const checkImagesLoaded = () => {
      loadedImages++;
      if (loadedImages === images.length) {
        setTimeout(() => {
          setShowLoading(false); 
          AOS.init();
        }, 1000);
      }
    };

    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = checkImagesLoaded;
      img.onerror = checkImagesLoaded; // Consider errors as loaded for simplicity
    });
  }, []);


  return (
    <div className={`${showLoading ? 'hide' : 'unhide'}`}>
      <Header/>
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      {!showLoading && (

      <div className='top_div_container'>
      <div className='top_div'>
        <img data-aos='fade-up' data-aos-duration='1200' data-aos-offset='200' className='title' src={homepageTitleImage}/>
        <p data-aos='fade-up' data-aos-duration='1200' data-aos-delay='600' data-aos-once='true' className='title_para'> A transformative toolkit poised to reshape the expansion of <br/> blockchain ecosystem</p>
        <Navlink to='/ecosystems' data-aos='fade-up' data-aos-duration='1200' data-aos-offset='200' data-aos-once='true' className="explore_button">
          <button>
            <img src={exploreButton} alt="Button Image"/>
          </button>
        </Navlink>
      </div>

      <div data-aos='zoom-out' data-aos-duration='1200' data-aos-delay='600' data-aos-once='true' className='circle_div'>
        <img src={circle}/>
      </div>
      

      <div className="whatis_container">
      <div className="left">
        <div data-aos='zoom-out-right' data-aos-duration='1200' data-aos-once='true' className="left_top">
        <div className="left_top_top">
          <img src={whatis_title}/>
        </div>
        <div className="left_top_bottom">
          Your Gateway to Diverse Ecosystems
        </div>
        </div>
        <div className="left_bottom">
          Web3 represents a promising frontier, offering a decentralized world brimming with innovation. However, amidst this promise lies a complex landscape, much like a puzzle with numerous interconnected ecosystems. At Onboarding.club, we're committed to simplifying this journey.
        </div>
      </div>
        <div data-aos='zoom-out-left' data-aos-duration='1200' data-aos-delay='200' data-aos-once='true' className="right">
          <img src={whatis} />
        </div>
      </div>
    
    

    <div className='toolkit'>
      <div className='toolkit_text'>
        A transformative toolkit poised to reshape the expansion of blockchain ecosystem
      </div>
      <div data-aos='fade-in' data-aos-duration='1000' data-aos-delay='400' data-aos-offset='100' data-aos-once='true' className='toolkit_png'>
        <img src={toolkit} />
      </div>
    </div>

    <div className='offerings'>
      <div className='offerings_title'>
        <img src={offerings_title}/>
      </div>
      <div data-aos='zoom-out-right' data-aos-duration='1000' data-aos-once='true' data-aos-easing='ease-out-sine' className='offerings_text'>
        Gigs, Hacks, Grants, Seed, Social,<br/> Resource Hub
      </div>
      <div className="parent">
        <div className="left-child">
          <img src={offerings_left} alt="Left Image"/>
        </div>
        <div className="right-child">
          <div className="top">
            <div className='top_left'>
              <img src={top_left}/>
            </div>
            <div className='top_right'>
              <img src={top_right_bg} className='top_right_bg'/>
              <img src={top_right_stellar} className='top_right_stellar'/>
              <img src={top_right_cosmos} className='top_right_cosmos'/>
              <img src={top_right_flow} className='top_right_flow'/>
            </div>
          </div>
          <div className="middle">
            <div className='middle_left'>
              <img className='middle_left_bg' src={middle_left_bg}/>
              <img className='middle_left_circle' src={middle_left_circle}/>
            </div>
            <div className='middle_right'>
              <img src={middle_right_bg} className='middle_right_bg'/>
              <img src={middle_right_card} className='middle_right_card'/>
            </div>
            
          </div>
          <div className="bottom">
            <div className='bottom_left'>
              <img src={bottom_left_bg} className='bottom_left_bg' />
              <img src={bottom_left_trophy} className='bottom_left_trophy' />
            </div>
            <div className='bottom_right'>
              <img src={bottom_right_bg} className='bottom_right_bg'/>
              <img src={bottom_right_coins} className='bottom_right_coins'/>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="parent2">
        <div className="top2">
          <div data-aos='fade-right' data-aos-duration='1000' data-aos-once='true' className="top2_left">
            <div className='top2_left_title'>
              Builder
            </div>
            <div class="bulletin-container">
              <div class="bulletin-item">Dive into curated resources to #buidl on exciting chains</div>
              <div class="bulletin-item">Seize global gigs, job opportunities, and community engagement</div>
              <div class="bulletin-item">One stop solution for your Proof of Work across ecosystems</div>
              <div class="bulletin-item">Transform visions into reality at hackathons, bolstered by grants and VC funding</div>
            </div>
            <button className='top2_left_button'>
              <img src={top2_left_button}/>
            </button>
          </div>
          <div data-aos='fade-left' data-aos-duration='1000' data-aos-once='true' className="top2_right">
            <img src={top2_right} className='top2_right_img'/>
          </div>
        </div>
        <div className="middle2">
          <div data-aos='fade-right' data-aos-duration='1000' data-aos-once='true' className="middle2_left">
            <img src={middle2_left} className='middle2_left_img'/>
          </div>
          <div data-aos='fade-left' data-aos-duration='1000' data-aos-once='true' className="middle2_right">
          <div className='middle2_right_title'>
              Organization
            </div>
            <div class="bulletin-container">
              <div class="bulletin-item">Tap into a global talent reservoir with multi-domain expertise.</div>
              <div class="bulletin-item">Showcase gigs and bounties; reward standout contributors.</div>
              <div class="bulletin-item">Engage vibrantly with an expansive community.</div>
              <div class="bulletin-item">Streamline recruitment with transparent, insightful processes.</div>
            </div>
            <button className='middle2_right_button'>
              <img src={middle2_right_button}/>
            </button>
          </div>
          
        </div>
        <div className="bottom2">
          <div data-aos='fade-right' data-aos-duration='1000' data-aos-once='true' className="bottom2_left">
            <div className='bottom2_left_title'>
              Foundation
            </div>
            <div class="bulletin-container">
              <div class="bulletin-item">Seamlessly welcome the rising community into your chain.</div>
              <div class="bulletin-item">Empower projects to harness community potential.</div>
              <div class="bulletin-item">Host dynamic events: hackathons, meetups, and much more.</div>
              <div class="bulletin-item">Channelize growth via grants and funding initiatives. </div>
            </div>
            <button className='bottom2_left_button'>
              <img src={bottom2_left_button}/>
            </button>
          </div>
          <div data-aos='fade-left' data-aos-duration='1000' data-aos-once='true' className="bottom2_right">
            <img src={bottom2_right} className='bottom2_right_img'/>
          </div>
        </div>
      </div>

      <div className='flow'>
        <div className='flow_title'>
          Flow
        </div>
        <img src={flow} className='flow_img'/>
      </div>
      
      <div data-aos='fade-up' data-aos-duration='800' data-aos-once='true' class="footer">
        <div class="footer_title_small">
            <img src={footer_title} />
            <div className='subtitle'>
            Discover, Connect, Create:<br/>Onboard with Blockchain.
            </div>
            <button>
              <img src={footer_button} />
            </button>
          </div>
        <div class="footer_top">
          <div class="footer_top_title_large">
            <img src={footer_title} />
            <div className='subtitle'>
            Discover, Connect, Create:<br/>Onboard with Blockchain.
            </div>
            <button>
              <img src={footer_button} />
            </button>
          </div>
          <div class="div2">
            <div className='div2_1'>
              Company
            </div>
            <div className='div2_2'>
              Contact Us
            </div>
            <div className='div2_3'>
              Our Story
            </div>
            <div className='div2_4'>
              Read our Manifesto
            </div>
          </div>
          <div class="div3">
            <div className='div3_1'>
              Ecosystems
            </div>
            <div className='div3_2'>
              <span>Flow</span>.onboarding.club
            </div>
            <div className='div3_3'>
              <span>Stellar</span>.onboarding.club
            </div>
            <div className='div3_4'>
              <span>Onboarding.club</span>.club
            </div>
          </div>
          <div class="div4">
            <div className='div4_1'>
              Legal
            </div>
            <div className='div4_2'>
              Privacy
            </div>
            <div className='div4_3'>
              Terms of Joining
            </div>
          </div>
        </div>
        <div class="footer_bottom">
          <div class="div1">Building a universe you can rely on remotely :&#41;</div>
          <div class="div2">
            <div className="div2_text">
              We are still refining our product and any feedback or support will help us improve the experience for you 
            </div>
            <button>
              <img src={footer_twitter_button}/>
            </button>
          </div>
        </div>
      </div>


    </div>
      )}
  </div>
    
  );
}
