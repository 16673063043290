import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import "./CreateGigPage.scss";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import DescriptionIcon from "@mui/icons-material/Description";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import BasicsComponent from "./BasicsComponent";
import DescriptionComponent from "./DescriptionComponent";
import QuestionsComponent from "./QuestionsComponent";
import RewardsPage from "./RewardsPage";
import Header from "../../components/Header/StellarHeader/Header";
import applicationsuccess from "../../assets/images/applicationsuccess.svg";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function CreateGigPage() {
  const { chain } = useParams();
  const [gigCreateData, setGigCreateData] = useState({
    name: "",
    tags: [],
    description: "",
    category: "",
    questions: [
      {
        category: "Textbox",
        title: "Link to your submission",
        subtitle: "Add your GitHub Repo, Notion or Figma prototype link",
      },
    ],
    bounty_currency: "",
    bounty_amounts: [""],
    expiry_date: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    eligibility: "",
    details: `<!-- Below is a sample template of what could be the data -->
### About the Bounty

Write details about the bounty in detail and what is expected out of the same.

### Evaluation Criteria

Write about the evaluation metrics and if there is something applicants need to keep in mind in particular.

`,
  });

  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`& .${stepConnectorClasses.label}`]: {
      color: "#ffffff",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#A193F6",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#A193F6",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      flexDirection: "column",
      height: 30,
      width: 3,
      border: 0,
      backgroundColor: "#3C3B3B",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#A193F6",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#A193F6",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <SettingsIcon />,
      2: <DescriptionIcon />,
      3: <DynamicFormIcon />,
      4: <AttachMoneyIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };
  const stepsData = [
    {
      label: "Basics",
      component: (
        <BasicsComponent
          formData={gigCreateData}
          setFormData={setGigCreateData}
        />
      ),
    },
    {
      label: "Description",
      component: (
        <DescriptionComponent
          formData={gigCreateData}
          setFormData={setGigCreateData}
        />
      ),
    },
    {
      label: "Question",
      component: (
        <QuestionsComponent
          formData={gigCreateData}
          setFormData={setGigCreateData}
        />
      ),
    },
    {
      label: "Rewards",
      component: (
        <RewardsPage formData={gigCreateData} setFormData={setGigCreateData} />
      ),
    },
  ];

  const handleNext = () => {
    if (!isLastStep) setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
    else {
      console.log("Create Gig Integration");
      const api = new OBCApi(localStorage.getItem("jwt"), chain);
      api
        .gigCreateGig(gigCreateData)
        .then(() => {
          setShowLoading(false);
          showSuccessSnack("You gig has been created!");
          setIsSubmitted(true);
        })
        .catch((err) => {
          setShowLoading(false);
          showErrorSnack(err);
        });
      setIsSubmitted(true);
    }
  };

  const handleBack = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const steps = ["Basics", "Description", "Question", "Rewards"];
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === steps.length - 1;
  const nextButtonText = isLastStep
    ? "Create Gig"
    : "Next: " + steps[activeStepIndex + 1];

  // useEffect(() => {
  //   const localUser = localStorage.getItem("user");
  //   if (localUser) {
  //     const data = JSON.parse(localUser);
  //     let flag = 0;
  //     console.log(data);
  //     data.organisations.forEach((element) => {
  //       console.log(element);
  //       if (element.chain === chain) {
  //         console.log("damnnn");
  //         flag = 1;
  //       }
  //     });
  //     if (flag === 0) {
  //       showErrorSnack("Please register your organisation first")
  //       navigate(`/${chain}/register-org`);
  //     }
  //   } else {
  //     localStorage.clear();
  //     navigate(`/${chain}/authenticate`);
  //   }
  // }, []);
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="creategigpage"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      {!isSubmitted ? (
        <div className="creategigpage_main">
          <div className="creategigpage_main_steppercontainer">
            <Stepper
              orientation="vertical"
              activeStep={activeStepIndex}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <div className="text-white text-sm">Step {index + 1}</div>
                    <div className="text-white font-semibold">{label}</div>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          <div className="creategigpage_main_formcontainer">
            <div className="creategigpage_main_formcontainer_title">
              Create /gig
            </div>
            <div className="creategigpage_main_formcontainer_subtitle">
              Tell us more about the work you want to get completed
            </div>
            <div className="creategigpage_main_formcontainer_form">
              <div className="creategigpage_main_formcontainer_form_header">
                {steps[activeStepIndex]}
              </div>
              <div className="creategigpage_main_formcontainer_form_content">
                {stepsData[activeStepIndex].component}
              </div>
              <div className="creategigpage_main_formcontainer_form_footer">
                <div className="creategigpage_main_formcontainer_form_footer_disclaimertext">
                  <span>*</span> The information is mandatory
                </div>
                <div className="creategigpage_main_formcontainer_form_footer_buttoncontainer">
                  {!isFirstStep && (
                    <div
                      className="creategigpage_main_formcontainer_form_footer_buttoncontainer_previous"
                      onClick={handleBack}
                    >
                      Previous Step
                    </div>
                  )}
                  <div
                    className="creategigpage_main_formcontainer_form_footer_buttoncontainer_next"
                    onClick={handleNext}
                  >
                    {nextButtonText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="registerorg_success">
          <div className="registerorg_success_icon">
            <img src={applicationsuccess} alt="success" />
          </div>
          <div className="registerorg_success_title">
            Your gig has been successfully created!
          </div>
          <div className="registerorg_success_subtitle">
            {`It will be live once it's verified!`}
          </div>
          <div className="registerorg_success_submit">
            Go to your Org Profile
          </div>
        </div>
      )}
    </div>
  );
}
