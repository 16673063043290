import React, { useState } from "react";
import "./Ecosystems.scss";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import stellar from "../../assets/images/stellarecosystem.png";
import cosmos from "../../assets/images/cosmosecosystem.png";
import flow from "../../assets/images/flowecosystem.png";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";

export default function Ecosystems() {
  const [showLoading, setShowLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const showErrorSnack = (message) => {
      enqueueSnackbar(message, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 3000,
      });
    };
    const showSuccessSnack = (message) => {
      enqueueSnackbar(message, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 3000,
      });
    };
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="ecosystems"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      <div className="ecosystems_main">
        <div className="ecosystems_main_title">Explore Ecosystems</div>
        <div className="ecosystems_main_subtitle">
          Get started expanding ecosystem with Gigs, Hackathons, Grants, and
          much more
        </div>
        <div className="ecosystems_main_container">
          <Link to="/cosmos" className="ecosystems_main_container_ecosystem">
            <img src={cosmos} alt="cosmos" />
          </Link>
          <Link to="/stellar" className="ecosystems_main_container_ecosystem">
            <img src={stellar} alt="stellar" />
          </Link>
          <Link to="/flow" className="ecosystems_main_container_ecosystem">
            <img src={flow} alt="flow" />
          </Link>
        </div>
      </div>
    </div>
  );
}
