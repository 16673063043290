import { Drawer } from "@mui/material";
import React, { useState } from "react";
import "./SubmissionsDrawer.scss";
import twitterc from "../../assets/images/twittercolored.svg";
import linkedinc from "../../assets/images/linkedincolored.svg";
import MDEditor from "@uiw/react-md-editor";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";

export default function SubmissionsDrawer({ isOpen, onClose }) {
  const [viewState, setViewState] = useState(0);
    const [showLoading, setShowLoading] = useState(false);

  const gig = {
    type: "Design",
    name: "App Redesign: Focus on UX & UI",
    tags: ["Figma", "UI/UX", "Product Research"],
    bountyAmount: "1300",
    description:
      "Redesign the IDE for Sorobix to include a project directory import structure and also talk about how it can change the narrative for user onbaording.",
    expiryDate: "October 31, 2023",
    createdDate: "August 31, 2023",
  };
  const submissions = [
    {
      name: "Rishabh Keshan",
      submittedOn: "22/09/2023",
      skills: ["Django", "Python"],
    },
    {
      name: "Rishabh Keshan",
      submittedOn: "22/09/2023",
      skills: ["Django", "Python"],
    },
    {
      name: "Rishabh Keshan",
      submittedOn: "22/09/2023",
      skills: ["Django", "Python"],
    },
    {
      name: "Rishabh Keshan",
      submittedOn: "22/09/2023",
      skills: ["Django", "Python"],
    },
  ];
  const profileData = {
    name: "Rishabh Keshan",
    username: "rishabhkeshan",
    bio: "I'm a passionate developer.",
    skills: ["JavaScript", "React", "Node.js", "Software", "Creative"],
    walletsConnected: [
      { name: "Freighter", address: "", blockchain: "Stellar" },
    ],
    twitter: "keshanrishabh",
    telegram: "rishabhkeshan",
    linkedin: "rishabhkeshan",
    discord: "johndoe#1234",
    projects: [
      {
        name: "Hostelzz",
        link: "https://google.com",
        description: "Where to stay? We can find it for you.",
        technologies: ["HTML", "CSS", "JS", "Rust"],
      },
      {
        name: "Meri Bachat",
        link: "https://google.com",
        description: "Your only solution to buying gift vouchers.",
        technologies: ["HTML", "CSS", "JS", "Rust"],
      },
    ],
    workExp: [
      {
        companyName: "Hungama",
        role: "Product Manager",
        location: "Remote",
        startDate: "Feb 2022",
        endDate: "May 2023",
        description:
          "Worked on integrating the metaverse, added pipelines and new horizons to the sandbox escape.",
      },
      {
        companyName: "Capx",
        role: "SDE Intern",
        location: "Bangalore",
        startDate: "Feb 2022",
        endDate: "May 2023",
        description:
          "Worked on integrating the metaverse, added pipelines and new horizons to the sandbox escape.",
      },
    ],
    gigs: [{}],
  };
  const questions = [
    {
      category: "Textbox",
      title: "Link to your submission",
      subtitle: "Add your GitHub Repo, Notion or Figma prototype link",
      answer: "https://google.com",
    },
    {
      category: "Markdown Input",
      title: "Link to your submission",
      subtitle: "Add your GitHub Repo, Notion or Figma prototype link",
      answer: `### About the Bounty

Write details about the bounty in detail and what is expected out of the same.

### Evaluation Criteria

Write about the evaluation metrics and if there is something applicants need to keep in mind in particular.`,
    },
    {
      category: "Textbox",
      title: "Link to your submission",
      subtitle: "Add your GitHub Repo, Notion or Figma prototype link",
      answer: "https://google.com",
    },
    {
      category: "Markdown Input",
      title: "Link to your submission",
      subtitle: "Add your GitHub Repo, Notion or Figma prototype link",
      answer: `### About the Bounty

Write details about the bounty in detail and what is expected out of the same.

### Evaluation Criteria

Write about the evaluation metrics and if there is something applicants need to keep in mind in particular.`,
    },
  ];
  const renderTagsWithDynamicLimit = (tags, maxCharCount) => {
    let currentCharCount = 0;
    let visibleTags = [];
    let hiddenTagsCount = 0;

    for (const tag of tags) {
      if (currentCharCount + tag.length <= maxCharCount) {
        currentCharCount += tag.length;
        visibleTags.push(tag);
      } else {
        hiddenTagsCount = tags.length - visibleTags.length;
        break;
      }
    }

    return (
      <>
        {visibleTags.map((tag, tagIndex) => (
          <span
            key={tagIndex}
            className="gigs_main_cardscontainer_cards_card_right_tags_tag"
          >
            {tag}
          </span>
        ))}
        {hiddenTagsCount > 0 && (
          <>
            <span className="gigs_main_cardscontainer_cards_card_right_tags_tag">
              +{hiddenTagsCount}
            </span>
          </>
        )}
      </>
    );
  };
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const showErrorSnack = (message) => {
      enqueueSnackbar(message, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 3000,
      });
    };
    const showSuccessSnack = (message) => {
      enqueueSnackbar(message, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 3000,
      });
    };
    const toggleDropdown = () => {
      setDropdownOpen(!isDropdownOpen);
    };
  return (
    <Drawer anchor={"bottom"} open={isOpen} className="submissionsdrawer">
      <div
        style={{ filter: showLoading ? "blur(20px)" : "none" }}
        className="submissionsdrawer_width"
      >
        <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
        <div className="submissionsdrawer_closecontainer">
          <button
            className="submissionsdrawer_closecontainer_closebutton"
            onClick={() => {
              onClose();
            }}
          >
            X
          </button>
        </div>
        {viewState === 0 ? (
          <div className="submissionsdrawer_all">
            <div className="submissionsdrawer_all_detailscontainer">
              <div className="submissionsdrawer_all_detailscontainer_top">
                <div className="submissionsdrawer_all_detailscontainer_top_left">
                  <div className="submissionsdrawer_all_detailscontainer_top_left_type">
                    {gig.type}
                  </div>
                  <div className="submissionsdrawer_all_detailscontainer_top_left_amount">{`🏆 ${gig.bountyAmount}`}</div>
                </div>
                {/* <div className="submissionsdrawer_all_detailscontainer_top_right">
                  Review and Post Results
                </div> */}
              </div>
              <div className="submissionsdrawer_all_detailscontainer_name">
                {gig.name}
              </div>
              <div className="submissionsdrawer_all_detailscontainer_submissions">
                <span>22</span> Submissions
              </div>
            </div>
            <div className="submissionsdrawer_all_submissionscontainer">
              {submissions.map((submission, index) => (
                <div
                  key={index}
                  className="submissionsdrawer_all_submissionscontainer_card"
                >
                  <div className="submissionsdrawer_all_submissionscontainer_card_name">
                    {submission.name}
                  </div>
                  <div className="submissionsdrawer_all_submissionscontainer_card_subtext">
                    Submitted On: <span>{submission.submittedOn}</span>
                  </div>
                  <div className="submissionsdrawer_all_submissionscontainer_card_other">
                    <div className="submissionsdrawer_all_submissionscontainer_card_other_tags">
                      {renderTagsWithDynamicLimit(submission.skills, 30)}
                    </div>
                    <div className="submissionsdrawer_all_submissionscontainer_card_other_buttons">
                      <div className="submissionsdrawer_all_submissionscontainer_card_other_buttons_declare">
                        Declare Winner
                      </div>
                      <div
                        onClick={() => {
                          setViewState(1);
                        }}
                        className="submissionsdrawer_all_submissionscontainer_card_other_buttons_view"
                      >
                        View Submission
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="submissionsdrawer_particular">
            <div
              onClick={() => {
                setViewState(0);
              }}
              className="submissionsdrawer_particular_subtext"
            >
              Back to Submissions
            </div>
            <div className="flex flex-col xl:flex-row gap-8 h-full w-full">
              <div className="submissionsdrawer_particular_left_outer_outer">
                <div className="submissionsdrawer_particular_left_outer">
                  <div className="submissionsdrawer_particular_left">
                    <div className="submissionsdrawer_particular_left_top">
                      <div className="submissionsdrawer_particular_left_profileimagecontainer">
                        {profileData.name
                          ? profileData.name[0].toUpperCase()
                          : ""}
                      </div>
                    </div>
                    <div className="submissionsdrawer_particular_left_namecontainer">
                      <div className="submissionsdrawer_particular_left_namecontainer_name">
                        {profileData.name}
                      </div>{" "}
                      <div className="submissionsdrawer_particular_left_namecontainer_editbtn">
                        Go to Profile
                      </div>
                    </div>
                    <div className="submissionsdrawer_particular_left_username">
                      @{profileData.username}
                    </div>
                    <div className="submissionsdrawer_particular_left_socialcontainer">
                      <div className="submissionsdrawer_particular_left_socialcontainer_social">
                        <img src={twitterc} alt="twitter" />
                        <div className="submissionsdrawer_particular_left_socialcontainer_social_text">
                          {profileData.twitter}
                        </div>
                      </div>
                      <div className="submissionsdrawer_particular_left_socialcontainer_social">
                        <img src={linkedinc} alt="linkedin" />
                        <div className="submissionsdrawer_particular_left_socialcontainer_social_text">
                          {profileData.linkedin}
                        </div>
                      </div>
                    </div>

                    <div className="submissionsdrawer_particular_left_infocontainer">
                      <div className="submissionsdrawer_particular_left_infocontainer_title">
                        Skills
                      </div>
                      <div className="submissionsdrawer_particular_left_infocontainer_tags">
                        {profileData?.skills.map((skill, index) => (
                          <div
                            key={index}
                            className="submissionsdrawer_particular_left_infocontainer_tags_tag"
                          >
                            {skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submissionsdrawer_particular_right_outer_outer">
                <div className="submissionsdrawer_particular_right_outer">
                  <div className="submissionsdrawer_particular_right">
                    <div className="submissionsdrawer_particular_right_declare">
                      <div className="submissionsdrawer_particular_right_declare_button">
                        Declare Winner
                      </div>
                    </div>

                    <div className="submissionsdrawer_particular_right_scrollcontainer">
                      {questions.map((question, index) => (
                        <div
                          key={index}
                          className="submissionsdrawer_particular_right_infocontainer"
                        >
                          <div className="submissionsdrawer_particular_right_infocontainer_title">
                            {`${index + 1})`} {question.title}
                          </div>
                          {question.category === "Markdown Input" ? (
                            <MDEditor.Markdown
                              source={question.answer}
                              className="submissionsdrawer_particular_right_infocontainer_value"
                              style={{
                                whiteSpace: "pre-wrap",
                                backgroundColor: "red",
                              }}
                            />
                          ) : (
                            <div className="submissionsdrawer_particular_right_infocontainer_value">
                              {question.answer}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}
