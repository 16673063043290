import React, { useState } from "react";
import "./Modal.scss";
import Loader from "../../components/Loader/Loader";
import OBCApi from "../../api/api";
import { useSnackbar } from "notistack";

export default function AddExperienceModal({ onClose }) {
  const [formData, setFormData] = useState({
    company_name: "",
    designation: "",
    location: "",
    work_description: "",
    start_date: "",
    end_date: "",
    is_still_working: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: !formData.is_still_working,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    setShowLoading(true);
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    const localUser = JSON.parse(localStorage.getItem("user"))
    localUser.experiences.push(formData)
    api
      .userUpdateUser(localUser)
      .then((res) => {
        setShowLoading(false);
        setIsSubmitted(true);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  };

  return (
    <div className="profilemodal">
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="profilemodal_content">
        <div className="profilemodal_content_header">
          <div className="profilemodal_content_header_title">
            Add Experience
          </div>
          <div onClick={onClose} className="profilemodal_content_header_cross">
            X
          </div>
        </div>
        <div className="profilemodal_content_main">
          <div className="profilemodal_content_main_container">
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Company Name
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="company_name"
                placeholder="Enter company name"
                value={formData.company_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Designation
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="designation"
                placeholder="Enter your designation"
                value={formData.designation}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Location
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="location"
                placeholder="Enter location"
                value={formData.location}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Work Description
              </div>
              <textarea
                rows="7"
                className="profilemodal_content_main_container_input"
                name="work_description"
                placeholder="Enter work description"
                value={formData.work_description}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Start Date
              </div>
              <input
                className="profilemodal_content_main_container_input date"
                type="date"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                End Date
              </div>
              <input
                className="profilemodal_content_main_container_input date"
                type="date"
                name="end_date"
                value={formData.end_date}
                onChange={handleInputChange}
                disabled={formData.is_still_working}
              />
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  name="is_still_working"
                  checked={formData.is_still_working}
                  onChange={handleInputChange}
                />
                <span className="checkbox-checkmark"></span>
                Still Working
              </label>
            </div>
          </div>
        </div>
        <div className="profilemodal_content_footer">
          <div
            onClick={handleSubmit}
            className="profilemodal_content_footer_button"
          >
            Add Experience
          </div>
        </div>
      </div>
    </div>
  );
}
