import React, { useEffect, useState } from "react";
import "./Modal.scss";
import linkedin from "../../assets/images/linp.svg";
import twitter from "../../assets/images/twtp.svg";
import discord from "../../assets/images/disp.svg";
import telegram from "../../assets/images/telep.svg";
import { Link } from "react-router-dom";
import EditableMultiInput from "../CreateGigPage/EditableMultiInput";
import OBCApi from "../../api/api";

export default function EditProfileModal({ onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    about: "",
    skills: [],
    twitter: "",
    telegram: "",
    linkedin: "",
    discord: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setFormData(user);
  }, []);

  const handleSubmit = () => {
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    api
      .userUpdateUser(formData)
      .then((res) => {
        setIsSubmitted(true);
      })
      .catch((err) => {
        //TODO: Handler Error
        setIsSubmitted(true);
      });
  };
  return (
    <div className="profilemodal">
      <div className="profilemodal_content">
        <div className="profilemodal_content_header">
          <div className="profilemodal_content_header_title">Profile</div>
          <div onClick={onClose} className="profilemodal_content_header_cross">
            X
          </div>
        </div>
        <div className="profilemodal_content_main">
          <div className="profilemodal_content_main_container">
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Name
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Username
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="username"
                placeholder="Enter your username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Bio
              </div>
              <textarea
                rows="7"
                className="profilemodal_content_main_container_input"
                name="about"
                placeholder="Tell us about yourself..."
                value={formData.about}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Skillset
              </div>
              <EditableMultiInput
                property="skills"
                value={formData.skills}
                setValue={handleSetValue}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Social Links
              </div>
              <div className="profilemodal_content_main_container_socials">
                <div className="profilemodal_content_main_container_socials_row">
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={twitter} alt="twitter" className="mr-2" />
                    <div className="profilemodal_content_main_container_socials_row_input_text">
                      twitter.com/
                    </div>
                    <input
                      type="text"
                      name="twitter"
                      value={formData.twitter}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={telegram} alt="twitter" className="mr-2" />
                    <div className="profilemodal_content_main_container_socials_row_input_text">
                      t.me/
                    </div>
                    <input
                      type="text"
                      name="telegram"
                      value={formData.telegram}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="profilemodal_content_main_container_socials_row">
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={linkedin} alt="linkedin" className="mr-2" />
                    <div className="profilemodal_content_main_container_socials_row_input_text">
                      linkedin.com/in/
                    </div>
                    <input
                      type="text"
                      name="linkedin"
                      id="linkedin"
                      value={formData.linkedin}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={discord} alt="twitter" className="mr-2" />
                    <input
                      type="text"
                      name="discord"
                      placeholder="Enter your Discord ID"
                      value={formData.discord}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profilemodal_content_footer">
          <div
            onClick={handleSubmit}
            className="profilemodal_content_footer_button"
          >
            Save Changes
          </div>
        </div>
      </div>
    </div>
  );
}
