import React, { useEffect, useState } from "react";
import Header from "../../components/Header/StellarHeader/Header";
import "./ResourceHubPage.scss";
import { useParams } from "react-router-dom";
import resources from "./resources.json";
import ResourceHubHeroGalaxy from "../../assets/images/hubelement.svg";
import { useNavigate } from "react-router-dom";
import MiniCourses from './MiniCourses.js';

import sdks from "../../assets/images/sdks.svg";
import docs from "../../assets/images/docs.svg";
import apis from "../../assets/images/apis.svg";

import stellarBg from "../../assets/images/stellarresource.svg";
import flowBg from "../../assets/images/flow.svg";
import cosmosBg from "../../assets/images/cosmos.svg";

import { useSnackbar } from "notistack";
import Loader from "../../components/Loader/Loader";

let contentBg;
let buildOnBg;

const allowedChains = ["stellar", "cosmos", "flow"];

export default function ResourceHubPage() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const { chain } = useParams();
  useEffect(() => {
    console.log(chain);
    if (!allowedChains.includes(chain)) {
      navigate("/ecosystems");
    }
  }, [chain, navigate]);
    const chainResources = resources[chain] || resources["others"];

  const chainBackgrounds = {
    stellar: {
      contentBg: stellarBg,
      buildOnBg:
        "https://assets-global.website-files.com/5deac75ecad2173c2ccccbc7/624df257e2ebf86c0fe0e9c9_StellarStackDiagram%20(1).png",
    },
    cosmos: {
      contentBg: cosmosBg,
      buildOnBg: "https://v1.cosmos.network/images/intro/04-sdk.svg",
    },
    flow: {
      contentBg: flowBg,
      buildOnBg:
        "https://assets-global.website-files.com/5f734f4dbd95382f4fdfa0ea/62f0de4b2f210850a33fd2c7_Flow-primer1-p-800.png",
    },
  };

  if (chainBackgrounds[chain]) {
    contentBg = chainBackgrounds[chain].contentBg;
    buildOnBg = chainBackgrounds[chain].buildOnBg;
  }

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="resourcehub"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="resourcehub_banner">
        <div className="resourcehub_banner_left">
          <div className="resourcehub_banner_left_title">/hub</div>
          <div className="resourcehub_banner_left_subtitle">{chain}</div>
        </div>
        <div className="resourcehub_banner_right">
          <img src={ResourceHubHeroGalaxy} alt="gig" />
        </div>
      </div>
      <div className="resourcehub_main">
        <div className="resourcehub_main_title">Kickstart Resources</div>
        <div className="resourcehub_main_subtitle">
          Learn from tutorials and guides, join relevant communities and
          kickstart your{" "}
          <span className="resourcehub_main_subtitle_span">{chain}</span>{" "}
          journey.
        </div>
        <div className="resourcehub_main_resourcecontainer">
          <div className="MiniCourses">
            <MiniCourses />
          </div>
        
          <div className="resourcehub_main_resourcecontainer_row flex-col">
            <div
              className="item-1"
              style={{ backgroundImage: `url(${contentBg})` }}
            >
              <div>{chainResources?.desc?.content}</div>
            </div>
            <div className="item-2">
              <iframe
                src={chainResources?.yt?.url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="resourcehub_main_resourcecontainer_row">
            <div
              className="item-3"
              onClick={() => {
                window.open(chainResources?.get_started?.url, "_blank");
              }}
            >
              {chainResources?.get_started?.title}
            </div>

            <div
              className="item-4"
              onClick={() => {
                window.open(chainResources?.community_channel?.url, "_blank");
              }}
            >
              {chainResources?.community_channel?.title}
            </div>

            <div
              className="item-5"
              onClick={() => {
                window.open(chainResources?.ide?.url, "_blank");
              }}
            >
              {chainResources?.ide.title}
            </div>
          </div>
          <div className="resourcehub_main_resourcecontainer_row">
            <div
              className="item-6"
              onClick={() => {
                window.open(chainResources?.wallet?.url, "_blank");
              }}
            >
              {chainResources?.wallet?.title}
            </div>

            <div
              className="item-7"
              onClick={() => {
                window.open(chainResources?.node_explorer?.url, "_blank");
              }}
            >
              {chainResources?.node_explorer?.title}
            </div>
            <div
              className="item-8"
              onClick={() => {
                window.open(chainResources?.beginners_guide?.url, "_blank");
              }}
            >
              {chainResources?.beginners_guide?.title}
            </div>
            <div
              className="item-9"
              onClick={() => {
                window.open(chainResources?.chain_foundation?.url, "_blank");
              }}
            >
              {chainResources?.chain_foundation?.title}
            </div>
          </div>
        </div>
        <div className="resourcehub_main_devcontainer">
          <div className="resourcehub_main_devcontainer_title">
            Developer Resources
          </div>
          <div className="resourcehub_main_devcontainer_subtitle">
            Setup and quickly start coding with these resources
          </div>
          <div className="resourcehub_main_devcontainer_buildcontainer">
            <div className="resourcehub_main_devcontainer_buildcontainer_left">
              <div className="resourcehub_main_devcontainer_buildcontainer_left_title">
                {chainResources?.build_on?.title}
              </div>
              <div className="resourcehub_main_devcontainer_buildcontainer_left_subtitle">
                {chainResources?.build_on?.content}
              </div>
              <div
                className="resourcehub_main_devcontainer_buildcontainer_left_button"
                onClick={() => {
                  window.open(chainResources?.build_on?.url, "_blank");
                }}
              >
                {chainResources?.build_on?.button_text}
              </div>
            </div>
            <div className="resourcehub_main_devcontainer_buildcontainer_right">
              <img src={buildOnBg} alt="build on stellar" />
            </div>
          </div>
          <div className="resourcehub_main_devcontainer_othercontainers">
            <div
              className="resourcehub_main_devcontainer_othercontainers_container"
              onClick={() => {
                window.open(chainResources?.sdk?.url, "_blank");
              }}
            >
              <img src={sdks} alt="sdks" />
              <div className="resourcehub_main_devcontainer_othercontainers_container_title">
                {chainResources?.sdk?.title}
              </div>
            </div>
            <div
              className="resourcehub_main_devcontainer_othercontainers_container"
              onClick={() => {
                window.open(chainResources?.docs?.url, "_blank");
              }}
            >
              <img src={docs} alt="docs" />
              <div className="resourcehub_main_devcontainer_othercontainers_container_title">
                {chainResources?.docs?.title}
              </div>
            </div>
            <div
              className="resourcehub_main_devcontainer_othercontainers_container"
              onClick={() => {
                window.open(chainResources?.other_docs?.url, "_blank");
              }}
            >
              <img src={apis} alt="apis" />
              <div className="resourcehub_main_devcontainer_othercontainers_container_title">
                {chainResources?.other_docs?.title}
              </div>
            </div>
          </div>
        </div>
        <div className="resourcehub_main_deepercontainer">
          <div className="resourcehub_main_deepercontainer_title">
            Dig Deeper
          </div>
          <div className="resourcehub_main_deepercontainer_subtitle">
            Other Resources which might be helpful
          </div>
          <div className="resourcehub_main_deepercontainer_containers">
            <div className="resourcehub_main_deepercontainer_containers_container">
              <div className="resourcehub_main_deepercontainer_containers_container_inner">
                <div className="resourcehub_main_deepercontainer_containers_container_inner_inner">
                  <div className="resourcehub_main_deepercontainer_containers_container_inner_inner_title">
                    {chainResources?.dig_deeper_1?.title}
                  </div>
                  <div
                    className="resourcehub_main_deepercontainer_containers_container_inner_inner_button"
                    onClick={() => {
                      window.open(chainResources?.dig_deeper_1?.url, "_blank");
                    }}
                  >
                    Learn More
                  </div>
                </div>
              </div>
            </div>

            <div className="resourcehub_main_deepercontainer_containers_container">
              <div className="resourcehub_main_deepercontainer_containers_container_inner">
                <div className="resourcehub_main_deepercontainer_containers_container_inner_inner">
                  <div className="resourcehub_main_deepercontainer_containers_container_inner_inner_title">
                    {chainResources?.dig_deeper_2?.title}
                  </div>
                  <div
                    className="resourcehub_main_deepercontainer_containers_container_inner_inner_button"
                    onClick={() => {
                      window.open(chainResources?.dig_deeper_2?.url, "_blank");
                    }}
                  >
                    Learn More
                  </div>
                </div>
              </div>
            </div>
            <div className="resourcehub_main_deepercontainer_containers_container">
              <div className="resourcehub_main_deepercontainer_containers_container_inner">
                <div className="resourcehub_main_deepercontainer_containers_container_inner_inner">
                  <div className="resourcehub_main_deepercontainer_containers_container_inner_inner_title">
                    {chainResources?.dig_deeper_3?.title}
                  </div>
                  <div
                    className="resourcehub_main_deepercontainer_containers_container_inner_inner_button"
                    onClick={() => {
                      window.open(chainResources?.dig_deeper_3?.url, "_blank");
                    }}
                  >
                    Learn More
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
