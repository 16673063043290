import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditableMultiInput from "./EditableMultiInput";
import MDEditor from "@uiw/react-md-editor";

function DescriptionComponent({ formData, setFormData }) {
  const categoryOptions = [
    "Design",
    "Frontend",
    "Backend",
    "Content Writing",
    "Full Stack",
    "Research",
    "Others",
  ];
  const [mdValue, setMdValue] = React.useState("**Hello world!!!**");

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };
  const handleCategoryChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      category: e.target.value,
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      expiryDate: e,
    }));
  };
  const [expiryDateTime, setExpiryDateTime] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const minExpDate = new Date(new Date().setDate(new Date().getDate() + 1));
  return (
    <div className="basicscomponent">
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Listing Description (150 characters)
        </div>
        <textarea
          type="text"
          name="description"
          className="basicscomponent_inputcontainer_value"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Enter listing description"
        />
      </div>
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Skills Needed
        </div>
        <EditableMultiInput
          property="tags"
          value={formData.tags}
          setValue={handleSetValue}
        />
      </div>
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Mention Eligibility Requirements (if any)
        </div>
        <textarea
          type="text"
          name="eligibility"
          className="basicscomponent_inputcontainer_value"
          value={formData.eligibility}
          onChange={handleInputChange}
          placeholder="Any past experience or mandates"
        />
      </div>
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Gig Description
        </div>
        <MDEditor
          style={{ backgroundColor: "#181818" }}
          className="mt-2"
          value={formData.details}
          name="details"
          onChange={(e) => {
            setFormData((prevData) => ({
              ...prevData,
              details: e,
            }));
          }}
        />
      </div>
    </div>
  );
}

export default DescriptionComponent;
