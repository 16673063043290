export const userEntity = {
  ID: 1,
  name: "",
  username: "",
  socials: [],
  skills: [],
  about: "",
  twitter: "",
  telegram: "",
  discord: "",
  linked_in: "",
  wallets_connected: null,
  technologies: null,
  projects: [
    {
      ID: 1,
      user_id: 1,
      project_name: "",
      project_url: "",
      project_description: "",
      technologies_used: [],
    },
  ],
  experiences: [],
  organisations: [
    {
      ID: 1,
      user_id: 0,
      name: "",
      username: "",
      about: "",
      logo: "",
      chain: "",
      email: "",
      company_url: "",
      linked_in: "",
      twitter: "",
      discord_invite: "",
      socials: [],
      domain_tags: null,
      gigs: null,
      is_approved: true,
      approved_by: 0,
    }
  ],
  Gigs: [
    {
      ID: 1,
      gig_id: 0,
      user_id: 0,
      username: "",
      skills: "",
      data: "",
      chain: "",
      is_selected: false,
    },
    {
      ID: 4,
      gig_id: 0,
      user_id: 0,
      username: "",
      skills: "",
      data: "",
      chain: "",
      is_selected: true,
    },
  ],
};

export const gigEntity = {
  ID: 0,
  organisation_id: 0,
  organisation_username: "",
  chain: "",
  is_approved: false,
  approved_by: 0,
  selected_gig: 0,
  selected_user: "",
  selected_user_id: 0,
  name: "",
  tags: [],
  description: "",
  category: "",
  questions: [],
  bounty_currency: "",
  bounty_amount: "",
  expiry_date: "",
  eligibility: "",
  details: "",
  gig_applications: null,
};

export const gigApplicationEntity = {
  ID: 0,
  gig_id: 0,
  user_id: 0,
  username: "",
  skills: "",
  data: "",
  chain: "",
};

export const orgEntity = {
  name: "",
  username: "",
  about: "",
  logo: "",
  chain: "",
  email: "",
  company_url: "",
  linked_in: "",
  twitter: "",
  discord_invite: "",
  socials: [],
  domain_tags: [],
};

export const gigApplicationWinner = {
  ID: 0,
  organisation_id: 0,
  organisation_username: "",
  chain: "",
  selected_user_id: 0,
  selected_gig: 0,
};