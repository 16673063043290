import React, { useEffect, useState } from "react";
import "./OrganizationPage.scss";
import Header from "../../components/Header/StellarHeader/Header";
import gigImage from "../../assets/images/gigimage.svg";
import orgicon from "../../assets/images/orgicon.svg";
import twitter from "../../assets/images/twitter.svg";
import linked_in from "../../assets/images/linkedin.svg";
import { Link } from "react-router-dom";
import SubmissionsDrawer from "./SubmissionsDrawer";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useParams } from "react-router-dom";

export default function OrganizationPage() {
  const { chain, orgname } = useParams();
  const [data, setData] = useState({
    type: "Design",
    username: "keybase",
    name: "App Redesign: Focus on UX & UI",
    domain_tags: ["Wallets", "DeFi", "Product Research"],
    createdBy: "Sorobix",
    bountyAmount: "2300 XLM",
    about:
      "Keybase has expanded from cryptocommunication into cryptocurrency by incorporating Stellar into their suite of apps (no official announcement yet)",
    expiryDate: "October 31, 2023",
    createdDate: "August 31, 2023",
    twitter: "keybase",
    linked_in: "keybase",
    followers: "20",
    icon: gigImage,
  })
  const [cardsData, setCardsData] = useState([
    {
      type: "Design",
      name: "App Redesign: Focus on UX & UI",
      tags: ["Figma", "UI/UX", "Product Research"],
      createdBy: "Sorobix",
      bountyAmount: "2300 XLM",
      about:
        "Redesign the IDE for Sorobix to include a project directory import structure and also talk about how it can change the narrative for user onbaording.",
      expiryDate: "October 31, 2023",
      createdDate: "August 31, 2023",
      icon: gigImage,
    }
  ])

  useEffect(() => {
    setShowLoading(true)
    const api = new OBCApi(localStorage.getItem("jwt"), chain);
    api.orgGetOrg(orgname).then((res) => {
      setShowLoading(false)
      console.log(res.data.data)
      setData(res.data.data)
      const bdaa = res.data.data.gigs
      bdaa.forEach(element => {
        element.icon = gigImage
      });
      setCardsData(res.data.data.gigs)
    }).catch((err) => {
      setShowLoading(false)
      showErrorSnack(err);
    })
  }, [])
  const calculateDaysAgo = (createdDate) => {
    const currentDate = new Date();
    const postedDate = new Date(createdDate);
    const timeDifference = currentDate - postedDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };
  const renderdomain_tagsWithDynamicLimit = (domain_tags, maxCharCount) => {
    console.log("asdfasdfasdf",domain_tags)
    let currentCharCount = 0;
    let visibledomain_tags = [];
    let hiddendomain_tagsCount = 0;

    for (const tag of domain_tags) {
      if (currentCharCount + tag.length <= maxCharCount) {
        currentCharCount += tag.length;
        visibledomain_tags.push(tag);
      } else {
        hiddendomain_tagsCount = domain_tags.length - visibledomain_tags.length;
        break;
      }
    }
    return (
      <>
        {visibledomain_tags.map((tag, tagIndex) => (
          <span
            key={tagIndex}
            className="orgpage_main_infocontainer_cardscontainer_cards_card_right_tags_tag"
          >
            {tag}
          </span>
        ))}
        {hiddendomain_tagsCount > 0 && (
          <>
            <span className="orgpage_main_infocontainer_cardscontainer_cards_card_right_tags_tag">
              +{hiddendomain_tagsCount}
            </span>
          </>
        )}
      </>
    );
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="orgpage"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="orgpage_main">
        <div className="orgpage_main_profilecontainer">
          <div className="orgpage_main_profilecontainer_left">
            <div className="orgpage_main_profilecontainer_left_image">
              <img src={data.logo} alt="icon" />
            </div>
            <div className="orgpage_main_profilecontainer_left_details">
              <div className="orgpage_main_profilecontainer_left_details_titlecontainer">
                <div className="orgpage_main_profilecontainer_left_details_titlecontainer_title">
                  {data.name}
                </div>
                <img src={orgicon} alt="org icon" />
                <div className="orgpage_main_profilecontainer_left_details_titlecontainer_editbtn">
                  Edit{" "}
                </div>
              </div>
              <div className="orgpage_main_profilecontainer_left_details_subtitle">
                {"@" + data.username}{" "}
              </div>
              <div className="orgpage_main_profilecontainer_left_details_text">
                {data.followers} Followers
              </div>
              <div className="orgpage_main_profilecontainer_left_details_status">
                Verified
              </div>
            </div>
          </div>
          <div className="orgpage_main_profilecontainer_center">
            <div className="orgpage_main_profilecontainer_center_title">
              About
            </div>
            <div className="orgpage_main_profilecontainer_center_desc">
              {data.about}
            </div>
            <div className="orgpage_main_profilecontainer_center_socialcontainer">
              <a
                href={`https://twitter.com/${data.twitter}`}
                className="orgpage_main_profilecontainer_center_socialcontainer_social"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="orgpage_main_profilecontainer_center_socialcontainer_social_image"
                  src={twitter}
                  alt="twitter"
                />
                <div className="orgpage_main_profilecontainer_center_socialcontainer_social_text">
                  {" "}
                  {data.twitter}
                </div>
              </a>
              <a
                href={`https://www.linkedin.com/in/${data.linked_in}`}
                className="orgpage_main_profilecontainer_center_socialcontainer_social"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="orgpage_main_profilecontainer_center_socialcontainer_social_image"
                  src={linked_in}
                  alt="linked_in"
                />
                <div className="orgpage_main_profilecontainer_center_socialcontainer_social_text">
                  {data.linked_in}
                </div>
              </a>
            </div>
          </div>
          <div className="orgpage_main_profilecontainer_right">
            <div className="orgpage_main_profilecontainer_right_title">
              Domains
            </div>
            <div className="orgpage_main_profilecontainer_right_tags">
              {data.domain_tags.map((tag, index) => (
                <span
                  key={index}
                  className="orgpage_main_profilecontainer_right_tags_tag"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="orgpage_main_infocontainer">
          <div className="orgpage_main_infocontainer_tabscontainer">
            <div className="orgpage_main_infocontainer_tabscontainer_left">
              <div className="orgpage_main_infocontainer_tabscontainer_tab active">
                Gigs
              </div>
              <div className="orgpage_main_infocontainer_tabscontainer_tab hidden md:block">
                Hackathons <span>Soon</span>
              </div>
              <div className="orgpage_main_infocontainer_tabscontainer_tab hidden md:block">
                Grants <span>Soon</span>
              </div>
            </div>
            <Link
              to="/stellar/gig/create"
              className="orgpage_main_infocontainer_tabscontainer_right"
            >
              <div>+</div>
              <div>Post a Gig</div>
            </Link>
          </div>
          <div className=" orgpage_main_infocontainer_cardscontainer">
            <div className=" orgpage_main_infocontainer_cardscontainer_cards">
              {cardsData.map((card, index) => (
                <div
                  key={index}
                  className="orgpage_main_infocontainer_cardscontainer_cards_card"
                >
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_top">
                    <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left">
                      <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_image">
                        <img src={card.icon} alt="gig" />
                      </div>
                      <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details">
                        <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_submission">
                          Submissions Open
                        </div>

                        <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_name">
                          {" "}
                          {card.name}
                        </div>
                        {/* <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_by">
                          by {card.createdBy}
                        </div> */}
                        <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_desc">
                          {" "}
                          {card.description}
                        </div>
                      </div>
                    </div>
                    <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right">
                      <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer">
                        <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button">
                          Edit
                        </div>
                        <div
                          onClick={() => {
                            console.log(card);
                            setIsModalOpen(true);
                          }}
                          className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button"
                        >
                          View Submissions
                        </div>
                      </div>

                      {/* <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_date">
                    Application ends on {(card.expiryDate)}
                  </div> */}
                    </div>
                  </div>
                  <div>
                    <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_date">
                      Posted {calculateDaysAgo(card.CreatedAt)} days ago
                    </div>
                    <div className="orgpage_main_infocontainer_cardscontainer_cards_card_bottom">
                      <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top">
                        <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top_type">
                          {card.category}
                        </div>
                        <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top_amount">{`🏆 ${card.bounty_amount}`}</div>
                      </div>
                      <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_tags">
                        {renderdomain_tagsWithDynamicLimit(card.tags, 30)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <SubmissionsDrawer isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}
