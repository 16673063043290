import React, { useEffect, useRef, useState } from "react";
import "./GigsPage.scss";
import Header from "../../components/Header/StellarHeader/Header";
import gigele from "../../assets/images/gigelement.svg";
import gigImage from "../../assets/images/gigimage.svg";
import GigDetailPage from "../GigDetailPage/GigDetailPage";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useParams } from "react-router-dom";


export default function GigsPage() {
  const { chain } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [selectedTab, setSelectedTab] = useState("All");
  const tabsRef = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeGig, setActiveGig] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  const [cardsData, setCardsData] = useState([
    {
      category: "Design",
      name: "App Redesign: Focus on UX & UI",
      tags: ["Figma", "UI/UX", "Product Research"],
      organisation_username: "Sorobix",
      bounty_amount: "2300 XLM",
      description:
        "Redesign the IDE for Sorobix to include a project directory import structure and also talk about how it can change the narrative for user onbaording.",
      expiry_date: "October 31, 2023",
      CreatedAt: "August 31, 2023",
      icon: gigImage,
    },
    {
      category: "Design",
      name: "StellarX Exchange Redesign",
      tags: ["Figma", "UI/UX", "Redesign"],
      organisation_username: "StellarX",
      bounty_amount: "1800 XLM",
      description:
        "Redesign the user interface and experience of the StellarX decentralized exchange platform. Enhance user engagement and trading experience.",
      expiry_date: "December 15, 2023",
      CreatedAt: "August 10, 2023",
      icon: gigImage,
    },
    {
      category: "Frontend",
      name: "Lobstr Wallet Frontend Development",
      tags: ["React", "Web3", "Stellar"],
      organisation_username: "Lobstr",
      bounty_amount: "2200 XLM",
      description:
        "Develop the frontend of the Lobstr Stellar wallet application. Implement wallet management features and provide a seamless user experience.",
      expiry_date: "November 25, 2023",
      CreatedAt: "June 5, 2023",
      icon: gigImage,
    },
    {
      category: "Backend",
      name: "Stellar Anchor Backend Development",
      tags: ["Smart Contracts", "Development", "Stellar"],
      organisation_username: "AnchorCorp",
      bounty_amount: "3200 XLM",
      description:
        "Develop backend infrastructure for Stellar anchors. Enable secure token issuance and management on the Stellar blockchain.",
      expiry_date: "December 10, 2023",
      CreatedAt: "July 5, 2023",
      icon: gigImage,
    },
    {
      category: "Content",
      name: "Stellar.org Educational Content",
      tags: ["Content Creation", "Stellar", "Tutorials"],
      organisation_username: "Stellar.org",
      bounty_amount: "1500 XLM",
      description:
        "Create educational content for Stellar.org, including tutorials and guides about the Stellar blockchain for a global audience.",
      expiry_date: "November 20, 2023",
      CreatedAt: "February 25, 2022",
      icon: gigImage,
    },
    {
      category: "Full Stack",
      name: "StellarSwap Full Stack Development",
      tags: ["React", "Node.js", "Stellar"],
      organisation_username: "StellarSwap",
      bounty_amount: "2800 XLM",
      description:
        "Develop a full-stack decentralized exchange (DEX) powered by Stellar. Implement frontend trading features and backend order matching.",
      expiry_date: "December 5, 2023",
      CreatedAt: "January 30, 2022",
      icon: gigImage,
    },
    {
      category: "Creative Writing",
      name: "Stellar Community Blog Posts",
      tags: ["Content Writing", "Stellar", "Blockchain"],
      organisation_username: "StellarCommunity",
      bounty_amount: "1200 XLM",
      description:
        "Write engaging blog posts for the Stellar community. Cover topics related to Stellar updates, partnerships, and community events.",
      expiry_date: "November 15, 2023",
      CreatedAt: "October 10, 2023",
      icon: gigImage,
    },
    {
      category: "Others",
      name: "Stellar Community Ambassador",
      tags: ["Community", "Engagement", "Stellar"],
      organisation_username: "CommunityLeader",
      bounty_amount: "900 XLM",
      description:
        "Become a Stellar Community Ambassador and engage with the community. Share knowledge, answer questions, and foster a strong Stellar community.",
      expiry_date: "November 1, 2023",
      CreatedAt: "October 8, 2023",
      icon: gigImage,
    },
  ])


  // useEffect(() => {
  //   setShowLoading(true)
  //   const api = new OBCApi("", chain)
  //   api.gigGetChainGig().then((res) => {
  //     setShowLoading(false)
  //     console.log(res.data.data)
  //     const bdaa = res.data.data
  //     bdaa.forEach(element => {
  //       element.icon = gigImage
  //     });
  //     // setCardsData(res.data.data)    //hard coded data for the time being
  //   }).catch((err) => {
  //     setShowLoading(false)
  //     showErrorSnack(err)
  //   })
  // }, [])

  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const openModal = (gigData) => {
    setActiveGig(gigData);
    setIsModalOpen(true);
    document.querySelector(".gigs").classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.querySelector(".gigs").classList.remove("modal-open");
  };

  const tabsData = [
    {
      name: "All",
    },
    {
      name: "Design",
    },
    {
      name: "Frontend",
    },
    {
      name: "Backend",
    },
    {
      name: "Content Writing",
    },
    {
      name: "Full Stack",
    },
    {
      name: "Research",
    },
    {
      name: "Others",
    },
  ];
  const filters = ["All Time", "On-Going", "Ending Soon"];

  const calculateDaysAgo = (CreatedAt) => {
    const currentDate = new Date();
    const postedDate = new Date(CreatedAt);
    const timeDifference = currentDate - postedDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };
  const renderTagsWithDynamicLimit = (tags, maxCharCount) => {
    let currentCharCount = 0;
    let visibleTags = [];
    let hiddenTagsCount = 0;

    for (const tag of tags) {
      if (currentCharCount + tag.length <= maxCharCount) {
        currentCharCount += tag.length;
        visibleTags.push(tag);
      } else {
        hiddenTagsCount = tags.length - visibleTags.length;
        break;
      }
    }

    return (
      <>
        {visibleTags.map((tag, tagIndex) => (
          <span
            key={tagIndex}
            className="gigs_main_cardscontainer_cards_card_right_tags_tag"
          >
            {tag}
          </span>
        ))}
        {hiddenTagsCount > 0 && (
          <>
            <span className="gigs_main_cardscontainer_cards_card_right_tags_tag">
              +{hiddenTagsCount}
            </span>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft - 95 ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth + 65 ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);
  const filteredCards =
    selectedTab === "All"
      ? cardsData
      : cardsData.filter(
          (card) => card.category.toLowerCase() === selectedTab.toLowerCase()
        );

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="gigs"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      <div className="gigs_banner">
        <div className="gigs_banner_left">
          <div className="gigs_banner_left_title">/gigs</div>
          <div className="gigs_banner_left_subtitle">Stellar</div>
        </div>
        <div className="gigs_banner_right">
          <img src={gigele} alt="gig" />
        </div>
      </div>
      <div className="gigs_main">
        <div className="gigs_main_title">Explore gigs</div>
        <div className="gigs_main_subtitle">
          Earn Money while supporting and contributing to the ecosystem growth
        </div>
        <div className=" gigs_main_category">
          <div className=" gigs_main_category_tabs">
            {tabsData.map((tab, idx) => {
              const isActive = idx === activeTabIndex;
              return (
                <>
                  <button
                    key={idx}
                    ref={(el) => (tabsRef.current[idx] = el)}
                    className={`pt-2 pb-1 font-bold ${
                      isActive ? "active-tab" : ""
                    }`}
                    onClick={() => {
                      setSelectedTab(tab.name);
                      setActiveTabIndex(idx);
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {tab.name}
                  </button>
                  <span
                    className="absolute bottom-0 block h-0.5 transition-all duration-300"
                    style={{
                      left: tabUnderlineLeft,
                      width: tabUnderlineWidth,
                      backgroundColor: "#D4D5F9",
                    }}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className=" gigs_main_cardscontainer">
          <div className=" gigs_main_cardscontainer_cards">
            {filteredCards.map((card, index) => (
              <div
                onClick={() => openModal(card)}
                key={index}
                className="gigs_main_cardscontainer_cards_card"
              >
                <div className="gigs_main_cardscontainer_cards_card_left">
                  <div className="gigs_main_cardscontainer_cards_card_left_image">
                    <img src={card.icon} alt="gig" />
                  </div>
                  <div className="gigs_main_cardscontainer_cards_card_left_details">
                    <div className="gigs_main_cardscontainer_cards_card_left_details_top">
                      <div className="gigs_main_cardscontainer_cards_card_left_details_top_type">
                        {card.category}
                      </div>
                      <div className="gigs_main_cardscontainer_cards_card_left_details_top_amount">{`🏆 ${card.bounty_amount}`}</div>
                    </div>
                    <div className="gigs_main_cardscontainer_cards_card_left_details_name">
                      {" "}
                      {card.name}
                    </div>
                    <div className="gigs_main_cardscontainer_cards_card_left_details_by">
                      by {card.organisation_username}
                    </div>
                    <div className="gigs_main_cardscontainer_cards_card_left_details_desc">
                      {" "}
                      {card.description}
                    </div>
                  </div>
                </div>
                <div className="gigs_main_cardscontainer_cards_card_right">
                  <div className="gigs_main_cardscontainer_cards_card_right_submission">
                    Submissions Open
                  </div>
                  <div className="gigs_main_cardscontainer_cards_card_right_tags">
                    {renderTagsWithDynamicLimit(card.tags, 30)}
                  </div>
                  <div className="gigs_main_cardscontainer_cards_card_right_date">
                    Posted {calculateDaysAgo(card.CreatedAt)} days ago
                  </div>
                  {/* <div className="gigs_main_cardscontainer_cards_card_right_date">
                    Application ends on {(card.expiry_date)}
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <GigDetailPage
        isOpen={isModalOpen}
        onClose={closeModal}
        gigData={activeGig}
      />
    </div>
  );
}
