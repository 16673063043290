import React, { useState, useEffect } from "react";
import Header from "../../components/Header/StellarHeader/Header";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";

export default function Auth() {
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const navigate = useNavigate();
  const handleGoogleLogin = (isFirst) => {
    console.log(isFirst);
    if (isFirst === "true") {
      navigate("/flow/profile-complete");
    } else {
      navigate("/ecosystems");
    }
  };

  useEffect(() => {
    setShowLoading(true);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    console.log(params);
    let googleAuth = params.get("token");
    let isFirst = params.get("first");
    console.log("Google auth", googleAuth);
    if (googleAuth === null) {
      setShowLoading(false);
      navigate("/");
    } else {
      setShowLoading(false);
      localStorage.setItem("jwt", googleAuth);
      handleGoogleLogin(isFirst);
    }
  }, []);

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="login"
    >
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="login_main">
        <div className="login_main_login">Redirecting you to explore</div>
        <div className="login_main_title">Onboarding.club</div>
      </div>
    </div>
  );
}
