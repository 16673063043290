import React, { useState } from "react";
import "./UserProfileSignup.scss";
import linked_in from "../../assets/images/linp.svg";
import twitter from "../../assets/images/twtp.svg";
import discord from "../../assets/images/disp.svg";
import telegram from "../../assets/images/telep.svg";
import Header from "../../components/Header/StellarHeader/Header";
import EditableMultiInput from "../CreateGigPage/EditableMultiInput";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useNavigate } from "react-router-dom";

export default function UserProfileSignup() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    about: "",
    skills: [],
    twitter: "",
    telegram: "",
    linked_in: "",
    discord: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };

  const handleSubmit = () => {
    setShowLoading(true);
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    api
      .userUpdateUser(formData)
      .then((res) => {
        setShowLoading(false);
        setIsSubmitted(true);
        navigate("/ecosystem");
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  };

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="profilesu"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="profilesu_main">
        <div className="profilesu_main_profilesu">
          Let's #buidl your profile
        </div>
        <div className="profilesu_main_title">Onboarding.club</div>
        <div className="profilesu_main_subtitle">
          Completing your profile helps get better opportunities
        </div>
        <div className="profilesu_main_container">
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Name</div>
            <input
              className="profilesu_main_container_input"
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Username</div>
            <input
              className="profilesu_main_container_input"
              type="text"
              name="username"
              placeholder="Enter your username"
              value={formData.username}
              onChange={handleInputChange}
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Bio</div>
            <textarea
              rows="3"
              className="profilesu_main_container_input"
              name="about"
              placeholder="Tell us about yourself..."
              value={formData.about}
              onChange={handleInputChange}
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Skillset</div>
            <EditableMultiInput
              property="skills"
              value={formData.skills}
              setValue={handleSetValue}
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Social Links</div>
            <div className="profilesu_main_container_socials">
              <div className="profilesu_main_container_socials_row">
                <div className="profilesu_main_container_socials_row_input">
                  <img src={twitter} alt="twitter" className="mr-2" />
                  <div className="profilesu_main_container_socials_row_input_text">
                    twitter.com/
                  </div>
                  <input
                    type="text"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profilesu_main_container_socials_row_input">
                  <img src={telegram} alt="telegram" className="mr-2" />
                  <div className="profilesu_main_container_socials_row_input_text">
                    t.me/
                  </div>
                  <input
                    type="text"
                    name="telegram"
                    value={formData.telegram}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="profilesu_main_container_socials_row">
                <div className="profilesu_main_container_socials_row_input">
                  <img src={linked_in} alt="linked_in" className="mr-2" />
                  <div className="profilesu_main_container_socials_row_input_text">
                    linked_in.com/in/
                  </div>
                  <input
                    type="text"
                    name="linked_in"
                    id="linked_in"
                    value={formData.linked_in}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="profilesu_main_container_socials_row_input">
                  <img src={discord} alt="twitter" className="mr-2" />
                  <input
                    type="text"
                    name="discord"
                    placeholder="Enter your Discord ID"
                    value={formData.discord}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={handleSubmit}
            className="profilesu_main_container_submit"
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
}
