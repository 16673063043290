import React, { useEffect, useState } from "react";
import "./RegisterOrg.scss";
import applicationsuccess from "../../assets/images/applicationsuccess.svg";
import { Link } from "react-router-dom";
import EditableMultiInput from "../CreateGigPage/EditableMultiInput";
import linkedin from "../../assets/images/linp.svg";
import twitter from "../../assets/images/twtp.svg";
import discord from "../../assets/images/disp.svg";
import telegram from "../../assets/images/telep.svg";
import Header from "../../components/Header/StellarHeader/Header";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useParams } from "react-router-dom";
import { orgEntity } from "../../api/types";
import { useNavigate } from "react-router-dom";

export default function RegisterOrg() {
  const { chain } = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!localStorage.getItem("jwt")) {
  //     //TODO: navigate to login
  //     localStorage.clear();
  //     navigate(`/${chain}/authenticate`);
  //   } else {
  //     const localUser = localStorage.getItem("user");
  //     if (localUser) {
  //       const data = JSON.parse(localUser);
  //       let flag = 0;
  //       let orgName = "";
  //       console.log(data);
  //       data.organisations.forEach((element) => {
  //         console.log(element);
  //         if (element.chain === chain) {
  //           console.log("damnnn");
  //           flag = 1;
  //           orgName = element.name;
  //         }
  //       });
  //       if (flag === 1) {
  //         showSuccessSnack("You already own an organisation :D")
  //         navigate(`/${chain}/org/${orgName}`);
  //       }
  //     }
  //   }
  // }, []);

  // Initialize state for the input fields
  const [formData, setFormData] = useState({
    orgLogo: "",
    projectName: "",
    contactEmail: "",
    description: "",
    domainTags: [],
    companyURL: "",
    linkedinURL: "",
    twitterURL: "",
    discordInviteURL: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setShowLoading(true);
    const entity = orgEntity;
    entity.username = formData.projectName;
    entity.logo = formData.orgLogo;
    entity.name = formData.projectName;
    entity.email = formData.contactEmail;
    entity.domain_tags = formData.domainTags;
    entity.company_url = formData.companyURL;
    entity.linked_in = formData.linkedinURL;
    entity.twitter = formData.twitterURL;
    entity.discord_invite = formData.discordInviteURL;
    console.log(localStorage.getItem("jwt"));
    const api = new OBCApi(localStorage.getItem("jwt"), chain);
    api
      .orgCreateOrg(entity)
      .then(() => {
        setShowLoading(false);
        showSuccessSnack("organisation has been created!");
        setIsSubmitted(true);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
    // setIsSubmitted(true);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        orgLogo: URL.createObjectURL(file),
      });
    }
  };
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="registerorg"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      {!isSubmitted ? (
        <div className="registerorg_main">
          <div className="registerorg_main_registerorg">
            Grants.Hacks.Gigs and more
          </div>
          <div className="registerorg_main_title">Register as Organization</div>
          <div className="registerorg_main_subtitle">
            Register your org to start listing grants, hacks, and more!
          </div>
          <div className="registerorg_main_container">
            <div className="registerorg_main_container_inputcontainer">
              <div className="registerorg_main_container_title">
                Upload Org Logo
              </div>
              <div className="registerorg_main_container_imginputcontainer">
                <label
                  htmlFor="orgLogoInput"
                  className="registerorg_main_container_imginputcontainer_imgpreview"
                >
                  {formData.orgLogo && (
                    <img
                      src={formData.orgLogo}
                      alt="Org Logo"
                      className="registerorg_main_container_imgpreview"
                    />
                  )}
                </label>
                <div className="registerorg_main_container_imginputcontainer_imgupload">
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={handleImageUpload}
                    id="orgLogoInput"
                  />
                  <label htmlFor="orgLogoInput" className="custom-file-label">
                    Upload Logo
                  </label>
                  <div>Image must be JPG or PNG below 5mb</div>
                </div>
              </div>
            </div>
            <div className="registerorg_main_container_inputcontainer">
              <div className="registerorg_main_container_title">
                Project Name
              </div>
              <input
                className="registerorg_main_container_input"
                type="text"
                name="projectName"
                placeholder="Enter project name"
                value={formData.projectName}
                onChange={handleInputChange}
              />
            </div>
            <div className="registerorg_main_container_inputcontainer">
              <div className="registerorg_main_container_title">
                Contact Email
              </div>
              <input
                className="registerorg_main_container_input"
                type="email"
                name="contactEmail"
                placeholder="Enter contact email"
                value={formData.contactEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="registerorg_main_container_inputcontainer">
              <div className="registerorg_main_container_title">
                Tell us about your project(visible in bio)
              </div>
              <textarea
                rows="3"
                className="registerorg_main_container_input"
                name="description"
                placeholder="Enter project description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="registerorg_main_container_inputcontainer">
              <div className="registerorg_main_container_title">
                Project Domains (DeFi, NFTs, etc)
              </div>
              <EditableMultiInput
                property="domainTags"
                value={formData.domainTags}
                setValue={handleInputChange}
              />
            </div>
            <div className="registerorg_main_container_inputcontainer">
              <div className="registerorg_main_container_title">
                Project URL
              </div>
              <input
                className="registerorg_main_container_input"
                type="email"
                name="companyURL"
                placeholder="Enter your project website URL"
                value={formData.companyURL}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilesu_main_container_inputcontainer">
              <div className="profilesu_main_container_title">Social Links</div>
              <div className="profilesu_main_container_socials">
                <div className="profilesu_main_container_socials_row">
                  <div className="profilesu_main_container_socials_row_input">
                    <img src={twitter} alt="twitter" className="mr-2" />
                    <div className="profilesu_main_container_socials_row_input_text">
                      twitter.com/
                    </div>
                    <input
                      type="text"
                      name="twitterURL"
                      value={formData.twitterURL}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="profilesu_main_container_socials_row_input">
                    <img src={linkedin} alt="linkedin" className="mr-2" />
                    <div className="profilesu_main_container_socials_row_input_text">
                      linkedin.com/in/
                    </div>
                    <input
                      type="text"
                      id="linkedin"
                      name="linkedinURL"
                      value={formData.linkedinURL}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="profilesu_main_container_socials_row">
                  <div className="profilesu_main_container_socials_row_discordinput">
                    <img src={discord} alt="twitter" className="mr-2" />
                    <input
                      type="text"
                      name="discordInviteURL"
                      placeholder="Discord invite link"
                      value={formData.discordInviteURL}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={handleSubmit}
              className="registerorg_main_container_submit"
            >
              Submit
            </div>
          </div>
        </div>
      ) : (
        <div className="registerorg_success">
          <div className="registerorg_success_icon">
            <img src={applicationsuccess} alt="success" />
          </div>
          <div className="registerorg_success_title">
            Your application has been received by us!
          </div>
          <div className="registerorg_success_subtitle">
            {`You can still create gigs, but they will be made public
            soon after the review :)`}
          </div>
          <Link
            to={`/${chain}/org/${formData.projectName}`}
            className="registerorg_success_submit"
          >
            Go to your Org Profile
          </Link>
        </div>
      )}
    </div>
  );
}
